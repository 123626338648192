import { Controller, useForm } from "react-hook-form";
import { useState } from "react";
import { vestResolver } from "@hookform/resolvers/vest";
import { Form, Button, Row, Col } from "react-bootstrap";
import useSlidingPanelActions from "actions/slidingPanel";
import Loader from "components/Loader";
import { getValidationSuite } from "./validationSuite";
import { removeEmptyFields } from "utils/form";
import { ActivityHistoryMoveModel } from "models/create/ActivityHistoryMoveModel";
import CustomSelect from "components/Select/Select";
import { getMatterClientsSummary, getOpenMattersSummary, moveActivityHistory } from "actions/matter";
import { DropDownOptionModel } from "models/view/DropDownOptionModel";
import FormErrorButton from "components/Buttons/FormErrorButton";
import { useAppSelector } from "hooks/appSelector";

type Props = {
  matterId: string,
  activityHistoryIds: string[],
  displayClient: boolean,
  syncMatterFiles?: Function,
}

export default function MoveActivityHistoryForm(props: Props) {
  const [genericErrors, setGenericErrors] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [destinationClients, setDestinationClients] = useState<DropDownOptionModel[]>([]);
  const [isLoadingDestinationClients, setIsLoadingDestinationClients] = useState(false);
  const slidingPanelActions = useSlidingPanelActions();
  const currentSlidingPanelState = useAppSelector((state) => state.slidingPanel);

  const {reset, control, handleSubmit, formState: {errors}} = useForm<ActivityHistoryMoveModel>({
    resolver: vestResolver(getValidationSuite(props.displayClient))
  });

  const onSubmit = handleSubmit((data) => submitData(data));

  async function submitData(data: ActivityHistoryMoveModel) {
    setIsLoading(true);
    data.activityHistoryIds = props.activityHistoryIds;
    removeEmptyFields(data);
    moveActivityHistory(props.matterId, data)
      .then((response) => {
        slidingPanelActions.clearSlidingPanel();
        reset();
        if(props.syncMatterFiles){
          props.syncMatterFiles();
        }
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const cancelForm = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    slidingPanelActions.clearSlidingPanel();
    reset();

    currentSlidingPanelState.onCancel && currentSlidingPanelState.onCancel();
  }
  
  const onChangeDestinationMatter = (value?: string) => {
    if(value) {
      populateDestinationClients(value);
    }
  }

  const populateDestinationClients = (destinationMatterId: string) => {
    setIsLoadingDestinationClients(true);
    getMatterClientsSummary(destinationMatterId).then((response) => {
      setDestinationClients(response.data);
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoadingDestinationClients(false);
    });
  }

  return (
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Form onSubmit={onSubmit} className="d-flex flex-column h-100">
        <Row>
          <Form.Group as={Col} sm={props.displayClient ? 6 : 12} controlId="destinationMatterId">
            <Form.Label className="required">Destination Matter</Form.Label>
            <Controller
              control={control}
              name="destinationMatterId"
              shouldUnregister={true}
              render={({field: { onChange, value, name, ref }}) => (
                <CustomSelect
                  id="destinationMatterId"
                  inputRef={ref}
                  className={`lp-select${errors?.destinationMatterId?.message ? ' invalid' : ''}`}
                  endpointCall={() => getOpenMattersSummary(props.matterId)}
                  value={value}
                  onChange={val => {onChange(val?.id ?? null); onChangeDestinationMatter(val?.id);}}
                />
              )}
            />
            <Form.Text className="lp-error">
              {errors?.destinationMatterId?.message && (errors.destinationMatterId.message)}
            </Form.Text>
          </Form.Group>

          {props.displayClient &&
            <Form.Group as={Col} sm={6} className="mt-4 mt-sm-0" controlId="destinationMatterParticipatingEntityId">
              <Form.Label className="required">Destination Client</Form.Label>
              <Controller
                control={control}
                name={`destinationMatterParticipatingEntityId`}
                shouldUnregister={true}
                render={({ field: { onChange, value, name, ref } }) => (
                  <CustomSelect
                    id="destinationMatterParticipatingEntityId"
                    inputRef={ref}
                    className={`lp-select${errors?.destinationMatterParticipatingEntityId?.message ? ' invalid' : ''}`}
                    options={destinationClients}
                    isLoading={isLoadingDestinationClients}
                    value={value}
                    onChange={val => onChange(val?.id ?? null)}
                  />
                )}
              />
              <Form.Text className="lp-error">
                {errors?.destinationMatterParticipatingEntityId?.message && (errors.destinationMatterParticipatingEntityId?.message)}
              </Form.Text>
            </Form.Group>
          }
        </Row>

        <div className="lp-slide-panel-sticky-bottom">
          <Form.Group className="d-flex justify-content-between">
            { Object.keys(errors).length
              ? <FormErrorButton text="Move" />
              : <Button variant="success" type="submit">Move</Button>
            }
            <Button variant="secondary-400" onClick={cancelForm}>Cancel</Button>
          </Form.Group>
        </div>
      </Form>
    </>
  );
}
