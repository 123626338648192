import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { vestResolver } from "@hookform/resolvers/vest";
import useGridActions from "actions/grid";
import { useAppSelector } from "hooks/appSelector";
import { Form, Button, Row, Col } from "react-bootstrap";
import useSlidingPanelActions from "actions/slidingPanel";
import { getValidationSuite } from "./validationSuite";
import Loader from "components/Loader/index";
import { getDefaultEntityRoleById, updateDefaultEntityRole } from "actions/settings";
import { CreateOrUpdateDefaultEntityRoleModel } from "models/create/CreateOrUpdateDefaultEntityRoleModel";
import { DefaultEntityRoleModel } from "models/view/DefaultEntityRoleModel";
import FormErrorButton from "components/Buttons/FormErrorButton";

type Props = {
  defaultEntityRoleId: string
}

export default function EditDefaultEntityRoleForm(props: Props) {
  const [genericErrors, setGenericErrors] = useState(null);
  const gridActions = useGridActions();
  const grid = useAppSelector((state) => state.grid);
  const slidingPanelActions = useSlidingPanelActions();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    getDefaultEntityRoleById(props.defaultEntityRoleId).then((response) => {
      reset(response.data);
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }, []);

  const {register, reset, control, watch, handleSubmit, formState: {errors}} = useForm<CreateOrUpdateDefaultEntityRoleModel>({
    resolver: vestResolver(getValidationSuite(props.defaultEntityRoleId!))
  });

  const onSubmit = handleSubmit((data) => submitData(data));

  async function submitData(data: CreateOrUpdateDefaultEntityRoleModel) {
    setIsLoading(true);
    updateDefaultEntityRole(props.defaultEntityRoleId, data).then((response) => {
      let index: number = grid.rowData.findIndex((x: any) => x.id === response.data.id);
      let newEntry: DefaultEntityRoleModel = {...response.data};
      let newArray: Array<any> = [...grid.rowData];
      newArray[index] = newEntry;
      gridActions.setGridRowData(newArray);
      
      slidingPanelActions.clearSlidingPanel();
      reset();
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }

  const cancelForm = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    slidingPanelActions.clearSlidingPanel();
    reset();
  }
      
  return (
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Form onSubmit={onSubmit} className="d-flex flex-column h-100">
        <Row>
          <Form.Group as={Col} controlId="name">
            <Form.Label className="required">Name</Form.Label>
            <Form.Control
              type="text"
              className={`${errors?.name?.message ? 'invalid' : ''}`}
              {...register("name", {shouldUnregister: true})}
            />
            <Form.Text className="lp-error">
              {errors?.name?.message && (errors.name.message)}
            </Form.Text>
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} sm={6} controlId="isLayClient">
            <Form.Label>Is Lay Client</Form.Label>
            <Controller
              control={control}
              name="isLayClient"
              shouldUnregister={true}
              render={({field: { onChange, value, name, ref }}) => (
                <Form.Check 
                  type="switch"
                  id="isLayClient">
                    <Form.Check.Input
                      className= "form-check-input"
                      ref={ref}
                      checked={value ?? false}
                      disabled={watch('isInstructingSolicitor')}
                      onChange={(ev: any) => onChange(ev.target.checked)}
                    />
                </Form.Check>
              )}
            />
          </Form.Group>

          <Form.Group as={Col} sm={6} controlId="isInstructingSolicitor">
            <Form.Label>Is Instructing Solicitor</Form.Label>
            <Controller
              control={control}
              name="isInstructingSolicitor"
              shouldUnregister={true}
              render={({field: { onChange, value, name, ref }}) => (
                <Form.Check 
                  type="switch"
                  id="isInstructingSolicitor">
                    <Form.Check.Input
                      className= "form-check-input"
                      ref={ref}
                      checked={value ?? false}
                      disabled={watch('isLayClient')}
                      onChange={(ev: any) => onChange(ev.target.checked)}
                    />
                </Form.Check>
              )}
            />
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} controlId="active">
            <Form.Label>Active</Form.Label>
            <Controller
              control={control}
              name="active"
              shouldUnregister={true}
              render={({field: { onChange, value, name, ref }}) => (
                <Form.Check 
                  type="switch"
                  id="active">
                    <Form.Check.Input
                      className= "form-check-input"
                      ref={ref}
                      checked={value ?? false}
                      onChange={(ev: any) => onChange(ev.target.checked)}
                    />
                </Form.Check>
              )}
            />
          </Form.Group>
        </Row>

        <div className="lp-slide-panel-sticky-bottom">
          <Form.Group className="d-flex justify-content-between">
            { Object.keys(errors).length
              ? <FormErrorButton text="Update" />
              : <Button variant="success" type="submit">Update</Button>
            }
            <Button variant="secondary-400" onClick={cancelForm}>Cancel</Button>
          </Form.Group>
        </div>
      </Form>
    </>
  );
}
