import { ColDef, ColGroupDef } from "ag-grid-community";
import ActionsCellRenderer from "components/Grid/ActionsCellRenderer/ActionsCellRenderer";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import { BooleanFilter } from "components/Grid/GridFilters/Filters/BooleanFilter";
import { BooleanFloatingFilter } from "components/Grid/GridFilters/FloatingFilters/BooleanFloatingFilter";
import CheckboxCellRenderer from "components/Grid/CheckboxCellRenderer";
import store from "state/store";
import { setSlidingPanelData } from "state/slidingPanelSlice";
import ViewMatterFile from "../MatterFile/ViewMatterFile/ViewMatterFile";
import { dateComparator } from "components/Grid/Comparators/DateComparator";
import { DropdownArraySearchFilter } from "components/Grid/GridFilters/Filters/DropdownArraySearchFilter";
import { DropdownFloatingFilter } from "components/Grid/GridFilters/FloatingFilters/DropdownFloatingFilter";
import {
  changeMatterInvoiceShowOnMatterSummary,
  decreasePriorityMatterActivityHistory,
  deleteMatterDisbursement,
  deleteMatterEmail,
  deleteMatterFile,
  deleteMatterIncidentalExpense,
  deleteMatterInvoice,
  deleteMatterNote,
  deleteMatterRecordableEvent,
  flagMatterActivityHistory,
  getMatterActivityHistorySubtypes,
  increasePriorityMatterActivityHistory,
  markMatterInvoiceAsDraft,
  unflagMatterActivityHistory
} from "actions/matter";
import { MatterActivityHistoryModel } from "models/view/MatterActivityHistoryModel";
import { MatterActivityHistoryTypes } from "enums/MatterActivityHistoryTypes";
import { DropdownFilter } from "components/Grid/GridFilters/Filters/DropdownFilter";
import ViewMatterNote from "../MatterNote/ViewMatterNote/ViewMatterNote";
import { ModalState, setModalData, setModalErrors, toggleModal, toggleModalLoadingState } from "state/modalSlice";
import { setRowData } from "state/gridSlice";
import MatterActivityHistoryColumnWithSubtypeIconCellRenderer from "components/Grid/MatterActivityHistoryColumnWithSubtypeIconCellRenderer";
import DropDownOptionListCellRenderer from "components/Grid/DropDownOptionListCellRenderer";
import { MatterActivityHistorySubtypes } from "enums/MatterActivityHistorySubtypes";
import PreviewMatterFile from "../MatterFile/PreviewMatterFile/PreviewMatterFile";
import ViewMatterRecordableEvent from "../MatterRecordableEvent/ViewMatterRecordableEvent/ViewMatterRecordableEvent";
import EditActivityHistoryTaxonomyTermsForm from "./ActivityHistoryTaxonomyTerms/EditActivityHistoryTaxonomyTerms/EditActivityHistoryTaxonomyTermsForm";
import { DateTimeFormatterForMatterActivityHistory } from "components/Grid/ValueFormatters/DateTimeFormatterForMatterActivityHistory";
import ViewMatterDisbursement from "../MatterDisbursement/ViewMatterDisbursement/ViewMatterDisbursement";
import PreviewMatterEmail from "../MatterEmail/PreviewMatterEmail/PreviewMatterEmail";
import ViewMatterEmail from "../MatterEmail/ViewMatterEmail/ViewMatterEmail";
import ViewMatterIncidentalExpense from "../MatterIncidentalExpense/ViewMatterIncidentalExpense/ViewMatterIncidentalExpense";
import SaveAttachments from "../MatterEmail/SaveAttachments/SaveAttachments";
import LinkMatterIncidentalExpenseForm from "../MatterIncidentalExpense/LinkMatterIncidentalExpense/LinkMatterIncidentalExpenseForm";
import UnlinkMatterIncidentalExpenseForm from "../MatterRecordableEvent/UnlinkMatterIncidentalExpense/UnlinkMatterIncidentalExpenseForm";
import ViewMatterInvoice from "../MatterInvoice/ViewMatterInvoice/ViewMatterInvoice";
import ViewMatterInvoicePayments from "../MatterInvoice/ViewMatterInvoicePayments/ViewMatterInvoicePayments";
import ViewMatterDisbursementPayments from "../MatterDisbursement/ViewMatterDisbursementPayments/ViewMatterDisbursementPayments";
import MatterIncidentalExpenseWriteOffForm from "../MatterIncidentalExpense/MatterIncidentalExpenseWriteOff/MatterIncidentalExpenseWriteOffForm";
import MatterDisbursementWriteOffForm from "../MatterDisbursement/MatterDisbursementWriteOff/MatterDisbursementWriteOffForm";
import MatterInvoiceWriteOffForm from "../MatterInvoice/MatterInvoiceWriteOff/MatterInvoiceWriteOffForm";
import { MatterInvoiceStatusesIds } from "enums/MatterInvoiceStatusesIds";
import { getPriorities, openUrlInNewtab } from "utils/misc";
import { TaxonomyModel } from "models/view/TaxonomyModel";
import { getMatterTaxonomyTermsSummary } from "actions/taxonomy";
import { TaxonomyTermModel } from "models/view/TaxonomyTermModel";
import TreeSelect from "rc-tree-select";
import { DropDownOptionListFormatter } from "components/Grid/ValueFormatters/DropDownOptionListFormatter";
import { CheckboxFormatter } from "components/Grid/ValueFormatters/CheckboxFormatter";
import MatterInvoiceAbatementForm from "../MatterInvoice/MatterInvoiceAbatement/MatterInvoiceAbatementForm";
import { UserPermissionsNames } from "enums/UserPermissionsNames";
import { UserRoleNames } from "enums/UserRoleNames";
import { MatterActivityHistorySubtypeFormatter } from "components/Grid/ValueFormatters/MatterActivityHistorySubtypeFormatter";
import ActivityTypeCellRenderer from "components/Grid/ActivityTypeCellRenderer";
import PriorityFormatter from "components/Grid/ValueFormatters/PriorityFormatter";
import FlagCellRenderer from "components/Grid/FlagCellRenderer";
import { Priority } from "enums/Priority";

const viewCallback = (rowData: MatterActivityHistoryModel, hasTaxonomies: boolean, syncMatterFiles: Function) => {
  switch(rowData.activityType) { 
    case MatterActivityHistoryTypes.RecordableEvent: { 
      store.dispatch(
        setSlidingPanelData(
          {
            isShown: true,
            allowNavigation: true,
            title: "View Recordable Event",
            children: <ViewMatterRecordableEvent
              matterId={rowData.matterId}
              recordableEventId={rowData.matterRecordableEventId!}
              isBilled={rowData.isBilled}
              activityHistoryId={rowData.id}
              isMatterClosed={rowData?.matter?.isClosed}
              hasTaxonomies={hasTaxonomies}
              syncMatterFiles={syncMatterFiles}
              isFlagged={rowData.isFlagged}
              priority={rowData.priority}
              showMoreButtons
            />,
          }
        )
      );
      return;
    }
    case MatterActivityHistoryTypes.Note: {
      store.dispatch(
        setSlidingPanelData(
          {
            isShown: true,
            allowNavigation: true,
            title: "View Matter Note",
            width: "40rem",
            children: <ViewMatterNote
              matterId={rowData.matterId}
              noteId={rowData.matterNoteId!}
              activitySubtype={rowData.activitySubtype!}
              activityHistoryId={rowData.id}
              isMatterClosed={rowData?.matter?.isClosed}
              hasTaxonomies={hasTaxonomies}
              syncMatterFiles={syncMatterFiles}
              isFlagged={rowData.isFlagged}
              priority={rowData.priority}
              showMoreButtons
            />,
          }
        )
      );
      return;
    }
    case MatterActivityHistoryTypes.File: { 
      store.dispatch(
        setSlidingPanelData(
          {
            isShown: true,
            allowNavigation: true,
            title: "View Matter File Details",
            children: <ViewMatterFile
              matterId={rowData.matterId}
              fileId={rowData.matterFileId!}
              activityHistoryId={rowData.id}
              isMatterClosed={rowData?.matter?.isClosed}
              hasTaxonomies={hasTaxonomies}
              syncMatterFiles={syncMatterFiles}
              isFlagged={rowData.isFlagged}
              priority={rowData.priority}
              activitySubtype={rowData.activitySubtype}
              showMoreButtons
            />,
          }
        )
      );
      return;
    }
    case MatterActivityHistoryTypes.Mail: { 
      store.dispatch(
        setSlidingPanelData(
          {
            isShown: true,
            allowNavigation: true,
            title: "View Matter Email Details",
            children: <ViewMatterEmail
              matterId={rowData.matterId}
              emailId={rowData.matterEmailId!}
              activityHistoryId={rowData.id}
              isMatterClosed={rowData?.matter?.isClosed}
              hasTaxonomies={hasTaxonomies}
              syncMatterFiles={syncMatterFiles}
              isFlagged={rowData.isFlagged}
              priority={rowData.priority}
              showMoreButtons
            />,
          }
        )
      );
      return;
    }
    case MatterActivityHistoryTypes.Disbursement: { 
      store.dispatch(
        setSlidingPanelData(
          {
            isShown: true,
            allowNavigation: true,
            title: "View Disbursement",
            children: <ViewMatterDisbursement
              matterId={rowData.matterId}
              matterDisbursementId={rowData.matterDisbursementId!}
              activityHistoryId={rowData.id}
              hasTaxonomies={hasTaxonomies}
              syncMatterFiles={syncMatterFiles}
              isFlagged={rowData.isFlagged}
              priority={rowData.priority}
              showMoreButtons
            />,
          }
        )
      );
      return;
    }
    case MatterActivityHistoryTypes.IncidentalExpense: { 
      store.dispatch(
        setSlidingPanelData(
          {
            isShown: true,
            allowNavigation: true,
            title: "View Incidental Expense",
            children: <ViewMatterIncidentalExpense
              matterId={rowData.matterId}
              matterIncidentalExpenseId={rowData.matterIncidentalExpenseId!}
              activityHistoryId={rowData.id}
              hasTaxonomies={hasTaxonomies}
              syncMatterFiles={syncMatterFiles}
              isFlagged={rowData.isFlagged}
              priority={rowData.priority}
              showMoreButtons
            />,
          }
        )
      );
      return;
    }
    case MatterActivityHistoryTypes.Invoice: { 
      store.dispatch(
        setSlidingPanelData(
          {
            isShown: true,
            allowNavigation: true,
            title: "View Invoice",
            children: <ViewMatterInvoice
              matterId={rowData.matterId}
              matterInvoiceId={rowData.matterInvoiceId!}
              activityHistoryId={rowData.id}
              isMatterClosed={rowData?.matter?.isClosed}
              hasTaxonomies={hasTaxonomies}
              syncMatterFiles={syncMatterFiles}
              isFlagged={rowData.isFlagged}
              priority={rowData.priority}
              showDocumentActions
              showMoreButtons
            />,
          }
        )
      );
      return;
    }
    default: { 
      return;
    }
  }
}

export const changeView = (rowData: MatterActivityHistoryModel, hasTaxonomies: boolean, syncMatterFiles: Function) => {
  if (rowData.activityType === MatterActivityHistoryTypes.Mail && !rowData.matter?.isClosed) {
    previewEmailCallback(rowData, hasTaxonomies, syncMatterFiles);
  }
  else if (rowData.activityType === MatterActivityHistoryTypes.File && !rowData.isFileDeleted  && !rowData.matter?.isClosed) {
    previewFileCallback(rowData, hasTaxonomies, syncMatterFiles);
  }
  else {
    viewCallback(rowData, hasTaxonomies, syncMatterFiles);
  }
}

const flagModal = (matterId: string, activityId: string) => {
  let modal: ModalState = {
    title: "Flag confirmation",
    body: "Are you sure you want to add a flag?",
    actionText: "Flag",
    onAction: () => flagCallback(matterId, activityId),
    show: false
  }
  store.dispatch(setModalData(modal));
  store.dispatch(toggleModal());
}

const flagCallback = (matterId: string, activityId: string) => {
  const gridState = store.getState().grid;
  store.dispatch(toggleModalLoadingState());
  flagMatterActivityHistory(matterId, activityId).then(() => {
    // Add flag to row without requesting row data
    const rowData = gridState.rowData;
    const entryIndex = rowData.findIndex((x: MatterActivityHistoryModel) => x.id === activityId);
    const newEntry = { ...rowData[entryIndex], isFlagged: true };
    const newArray = [...rowData];
    newArray[entryIndex] = newEntry;
    store.dispatch(setRowData(newArray));

    store.dispatch(toggleModal());
  })
  .catch((error) => {
    store.dispatch(setModalErrors(error.response?.data?.Message ?? error.message));
  })
  .finally(() => {
    store.dispatch(toggleModalLoadingState());
  });
  return;
}

const unflagModal = (matterId: string, activityId: string) => {
  let modal: ModalState = {
    title: "Unflag confirmation",
    body: "Are you sure you want to remove this flag?",
    actionText: "Unflag",
    onAction: () => unflagCallback(matterId, activityId),
    show: false
  }
  store.dispatch(setModalData(modal));
  store.dispatch(toggleModal());
}

const unflagCallback = (matterId: string, activityId: string) => {
  const gridState = store.getState().grid;
  store.dispatch(toggleModalLoadingState());
  unflagMatterActivityHistory(matterId, activityId).then(() => {
    // Add flag to row without requesting row data
    const rowData = gridState.rowData;
    const entryIndex = rowData.findIndex((x: MatterActivityHistoryModel) => x.id === activityId);
    const newEntry = { ...rowData[entryIndex], isFlagged: false };
    const newArray = [...rowData];
    newArray[entryIndex] = newEntry;
    store.dispatch(setRowData(newArray));

    store.dispatch(toggleModal());
  })
  .catch((error) => {
    store.dispatch(setModalErrors(error.response?.data?.Message ?? error.message));
  })
  .finally(() => {
    store.dispatch(toggleModalLoadingState());
  });
  return;
}

const increasePriorityModal = (matterId: string, activityId: string, triggerReloadPage: Function) => {
  let modal: ModalState = {
    title: "Increase priority confirmation",
    body: "Are you sure you want to increase the priority?",
    actionText: "Increase",
    onAction: () => increasePriorityCallback(matterId, activityId, triggerReloadPage),
    show: false
  }
  store.dispatch(setModalData(modal));
  store.dispatch(toggleModal());
}

const increasePriorityCallback = (matterId: string, activityId: string, triggerReloadPage: Function) => {
  store.dispatch(toggleModalLoadingState());
  increasePriorityMatterActivityHistory(matterId, activityId).then(() => {
    triggerReloadPage(true, false);
    store.dispatch(toggleModal());
  })
  .catch((error) => {
    store.dispatch(setModalErrors(error.response?.data?.Message ?? error.message));
  })
  .finally(() => {
    store.dispatch(toggleModalLoadingState());
  });
  return;
}

const decreasePriorityModal = (matterId: string, activityId: string, triggerReloadPage: Function) => {
  let modal: ModalState = {
    title: "Decrease priority confirmation",
    body: "Are you sure you want to decrease the priority?",
    actionText: "Decrease",
    onAction: () => decreasePriorityCallback(matterId, activityId, triggerReloadPage),
    show: false
  }
  store.dispatch(setModalData(modal));
  store.dispatch(toggleModal());
}

const decreasePriorityCallback = (matterId: string, activityId: string, triggerReloadPage: Function) => {
  store.dispatch(toggleModalLoadingState());
  decreasePriorityMatterActivityHistory(matterId, activityId).then(() => {
    triggerReloadPage(true, false);
    store.dispatch(toggleModal());
  })
  .catch((error) => {
    store.dispatch(setModalErrors(error.response?.data?.Message ?? error.message));
  })
  .finally(() => {
    store.dispatch(toggleModalLoadingState());
  });
  return;
}

const deleteModal = (rowData: MatterActivityHistoryModel, triggerReloadPage: Function, syncMatterFiles: Function) => {
  switch(rowData.activityType) { 
    case MatterActivityHistoryTypes.RecordableEvent: { 
      let modal: ModalState = {
        title: "Delete confirmation",
        body: "Are you sure you want to delete this Recordable Event?",
        actionText: "Delete",
        actionVariant: "danger",
        onAction: () => deleteCallback(rowData.matterId, rowData.matterRecordableEventId!, rowData.activityType, triggerReloadPage, syncMatterFiles),
        show: false
      }
      store.dispatch(setModalData(modal));
      store.dispatch(toggleModal());
      return;
    }
    case MatterActivityHistoryTypes.Note: {
      let modal: ModalState = {
        title: "Delete confirmation",
        body: "Are you sure you want to delete this Matter Note?",
        actionText: "Delete",
        actionVariant: "danger",
        onAction: () => deleteCallback(rowData.matterId, rowData.matterNoteId!, rowData.activityType, triggerReloadPage, syncMatterFiles),
        show: false
      }
      store.dispatch(setModalData(modal));
      store.dispatch(toggleModal());
      return;
    }
    case MatterActivityHistoryTypes.File: { 
      let modal: ModalState = {
        title: "Delete confirmation",
        body: "Are you sure you want to delete this Matter File?",
        actionText: "Delete",
        actionVariant: "danger",
        onAction: () => deleteCallback(rowData.matterId, rowData.matterFileId!, rowData.activityType, triggerReloadPage, syncMatterFiles),
        show: false
      }
      store.dispatch(setModalData(modal));
      store.dispatch(toggleModal());
      return;
    }
    case MatterActivityHistoryTypes.Disbursement: {
      let modal: ModalState = {
        title: "Delete confirmation",
        body: "Are you sure you want to delete this Matter Disbursement?",
        actionText: "Delete",
        actionVariant: "danger",
        onAction: () => deleteCallback(rowData.matterId, rowData.matterDisbursementId!, rowData.activityType, triggerReloadPage, syncMatterFiles),
        show: false
      }
      store.dispatch(setModalData(modal));
      store.dispatch(toggleModal());
      return;
    }
    case MatterActivityHistoryTypes.IncidentalExpense: {
      let modal: ModalState = {
        title: "Delete confirmation",
        body: "Are you sure you want to delete this Matter Incidental Expense?",
        actionText: "Delete",
        actionVariant: "danger",
        onAction: () => deleteCallback(rowData.matterId, rowData.matterIncidentalExpenseId!, rowData.activityType, triggerReloadPage, syncMatterFiles),
        show: false
      }
      store.dispatch(setModalData(modal));
      store.dispatch(toggleModal());
      return;
    }
    case MatterActivityHistoryTypes.Invoice: {
      let modal: ModalState = {
        title: "Delete confirmation",
        body: "Are you sure you want to delete this Matter Invoice?",
        actionText: "Delete",
        actionVariant: "danger",
        onAction: () => deleteCallback(rowData.matterId, rowData.matterInvoiceId!, rowData.activityType, triggerReloadPage, syncMatterFiles),
        show: false
      }
      store.dispatch(setModalData(modal));
      store.dispatch(toggleModal());
      return;
    }
    case MatterActivityHistoryTypes.Mail: { 
      let modal: ModalState = {
        title: "Delete confirmation",
        body: "Are you sure you want to delete this Matter Email? This will also delete the associated Recordable Events. (if there are any)",
        actionText: "Delete",
        actionVariant: "danger",
        onAction: () => deleteCallback(rowData.matterId, rowData.matterEmailId!, rowData.activityType, triggerReloadPage, syncMatterFiles),
        show: false
      }
      store.dispatch(setModalData(modal));
      store.dispatch(toggleModal());
      return;
    }
    default: {
      return;
    }
  }
};

const deleteCallback = (matterId: string, activityId: string, activityType: number, triggerReloadPage: Function, syncMatterFiles: Function) => {
  switch(activityType) { 
    case MatterActivityHistoryTypes.RecordableEvent: { 
      const gridState = store.getState().grid;
      store.dispatch(toggleModalLoadingState());
      deleteMatterRecordableEvent(matterId, activityId).then(() => {
        triggerReloadPage(false);
        store.dispatch(setRowData(gridState.rowData.filter((x: MatterActivityHistoryModel) => x.matterRecordableEventId !== activityId)));
        store.dispatch(toggleModal());
      })
      .catch((error) => {
        store.dispatch(setModalErrors(error.response?.data?.Message ?? error.message));
      })
      .finally(() => {
        store.dispatch(toggleModalLoadingState());
      });
      return;
    }
    case MatterActivityHistoryTypes.Note: {
      const gridState = store.getState().grid;
      store.dispatch(toggleModalLoadingState());
      deleteMatterNote(matterId, activityId).then(() => {
        store.dispatch(setRowData(gridState.rowData.filter((x: MatterActivityHistoryModel) => x.matterNoteId !== activityId)));
        store.dispatch(toggleModal());
      })
      .catch((error) => {
        store.dispatch(setModalErrors(error.response?.data?.Message ?? error.message));
      })
      .finally(() => {
        store.dispatch(toggleModalLoadingState());
      });
      return;
    }
    case MatterActivityHistoryTypes.File: {
      store.dispatch(toggleModalLoadingState());
      deleteMatterFile(matterId, activityId).then(() => {
        triggerReloadPage(false);
        syncMatterFiles && syncMatterFiles();
        store.dispatch(toggleModal());
      })
      .catch((error) => {
        store.dispatch(setModalErrors(error.response?.data?.Message ?? error.message));
      })
      .finally(() => {
        store.dispatch(toggleModalLoadingState());
      });
      return;
    }
    case MatterActivityHistoryTypes.Disbursement: {
      const gridState = store.getState().grid;
      store.dispatch(toggleModalLoadingState());
      deleteMatterDisbursement(matterId, activityId).then(() => {
        triggerReloadPage(false);
        store.dispatch(setRowData(gridState.rowData.filter((x: MatterActivityHistoryModel) => x.matterDisbursementId !== activityId)));
        store.dispatch(toggleModal());
      })
      .catch((error) => {
        store.dispatch(setModalErrors(error.response?.data?.Message ?? error.message));
      })
      .finally(() => {
        store.dispatch(toggleModalLoadingState());
      });
      return;
    }
    case MatterActivityHistoryTypes.IncidentalExpense: {
      const gridState = store.getState().grid;
      store.dispatch(toggleModalLoadingState());
      deleteMatterIncidentalExpense(matterId, activityId).then(() => {
        triggerReloadPage(false);
        store.dispatch(setRowData(gridState.rowData.filter((x: MatterActivityHistoryModel) => x.matterIncidentalExpenseId !== activityId)));
        store.dispatch(toggleModal());
      })
      .catch((error) => {
        store.dispatch(setModalErrors(error.response?.data?.Message ?? error.message));
      })
      .finally(() => {
        store.dispatch(toggleModalLoadingState());
      });
      return;
    }
    case MatterActivityHistoryTypes.Invoice: {
      store.dispatch(toggleModalLoadingState());
      deleteMatterInvoice(matterId, activityId).then(() => {
        triggerReloadPage(false);
        syncMatterFiles && syncMatterFiles();
        store.dispatch(toggleModal());
      })
      .catch((error) => {
        store.dispatch(setModalErrors(error.response?.data?.Message ?? error.message));
      })
      .finally(() => {
        store.dispatch(toggleModalLoadingState());
      });
      return;
    }
    case MatterActivityHistoryTypes.Mail: {
      store.dispatch(toggleModalLoadingState());
      deleteMatterEmail(matterId, activityId).then(() => {
        //reload the whole grid, because we might have RecordableEvents that changed by deleting the email
        triggerReloadPage();
        store.dispatch(toggleModal());
      })
      .catch((error) => {
        store.dispatch(setModalErrors(error.response?.data?.Message ?? error.message));
      })
      .finally(() => {
        store.dispatch(toggleModalLoadingState());
      });
      return;
    }
    default: {
      return;
    }
  }
};

const editTaxonomyTermsCallback = (matterId: string, activityHistoryId: string) => {
  store.dispatch(
    setSlidingPanelData(
      {
        isShown: true,
        title: "Edit Taxonomy Terms",
        children: <EditActivityHistoryTaxonomyTermsForm matterId={matterId} activityHistoryId={activityHistoryId} />
      }
    )
  );
}

const previewFileCallback = (rowData: MatterActivityHistoryModel, hasTaxonomies: boolean, syncMatterFiles: Function) => {
  store.dispatch(
    setSlidingPanelData(
      {
        isShown: true,
        allowNavigation: true,
        title: "Preview Matter File",
        children: <PreviewMatterFile
          matterId={rowData.matterId}
          fileId={rowData.matterFileId!}
          activityHistoryId={rowData.id}
          isMatterClosed={rowData?.matter?.isClosed}
          hasTaxonomies={hasTaxonomies}
          syncMatterFiles={syncMatterFiles}
          isFlagged={rowData.isFlagged}
          priority={rowData.priority}
          activitySubtype={rowData.activitySubtype}
          showMoreButtons
        />
      }
    )
  );
}

const previewEmailCallback = (rowData: MatterActivityHistoryModel, hasTaxonomies: boolean, syncMatterFiles: Function) => {
  store.dispatch(
    setSlidingPanelData(
      {
        isShown: true,
        allowNavigation: true,
        title: "Preview Matter Email",
        children: <PreviewMatterEmail
          matterId={rowData.matterId}
          emailId={rowData.matterEmailId!}
          activityHistoryId={rowData.id}
          isMatterClosed={rowData?.matter?.isClosed}
          hasTaxonomies={hasTaxonomies}
          syncMatterFiles={syncMatterFiles}
          isFlagged={rowData.isFlagged}
          priority={rowData.priority}
          showMoreButtons
        />
      }
    )
  );
}

const saveAttachmentsCallback = (matterId: string, emailId: string, syncMatterFiles: Function) => {
  store.dispatch(
    setSlidingPanelData(
      {
        isShown: true,
        title: "Save Attachments",
        children: <SaveAttachments matterId={matterId} emailId={emailId} syncMatterFiles={syncMatterFiles}/>,
      }
    )
  );
}

const linkMatterIncidentalExpenseCallback = (matterId: string, matterIncidentalExpenseId: string) => {
  store.dispatch(
    setSlidingPanelData(
      {
        isShown: true,
        title: "Link Incidental Expense",
        children: <LinkMatterIncidentalExpenseForm matterId={matterId} matterIncidentalExpenseId={matterIncidentalExpenseId} />,
      }
    )
  );
}

const unlinkMatterIncidentalExpenseCallback = (matterId: string, matterRecordableEventId: string) => {
  store.dispatch(
    setSlidingPanelData(
      {
        isShown: true,
        title: "Unlink Incidental Expense",
        children: <UnlinkMatterIncidentalExpenseForm matterId={matterId} recordableEventId={matterRecordableEventId} />,
      }
    )
  );
}

const paymentCallback = (matterId: string, rowData: MatterActivityHistoryModel) => {
  switch(rowData.activityType) { 
    case MatterActivityHistoryTypes.Disbursement: { 
      store.dispatch(
        setSlidingPanelData(
          {
            isShown: true,
            title: "Disbursement Payments",
            children: <ViewMatterDisbursementPayments
                matterId={rowData.matterId}
                matterDisbursementId={rowData.matterDisbursementId!}
              />
          }
        )
      );
      return;
    }
    case MatterActivityHistoryTypes.Invoice: { 
      store.dispatch(
        setSlidingPanelData(
          {
            isShown: true,
            title: "Invoice Payments",
            children: <ViewMatterInvoicePayments
                matterId={rowData.matterId}
                matterInvoiceId={rowData.matterInvoiceId!}
              />
          }
        )
      );
      return;
    }
    default: { 
      return;
    }
  }
}

const writeOffCallback = (matterId: string, rowData: MatterActivityHistoryModel) => {
  switch(rowData.activityType) { 
    case MatterActivityHistoryTypes.Disbursement: { 
      store.dispatch(
        setSlidingPanelData(
          {
            isShown: true,
            title: "Disbursement Write-Off",
            children: <MatterDisbursementWriteOffForm
                matterId={matterId}
                matterDisbursementId={rowData.matterDisbursementId!}
              />
          }
        )
      );
      return;
    }
    case MatterActivityHistoryTypes.IncidentalExpense: { 
      store.dispatch(
        setSlidingPanelData(
          {
            isShown: true,
            title: "Incidental Expense Write-Off",
            children: <MatterIncidentalExpenseWriteOffForm
                matterId={matterId}
                matterIncidentalExpenseId={rowData.matterIncidentalExpenseId!}
              />
          }
        )
      );
      return;
    }
    case MatterActivityHistoryTypes.Invoice: { 
      store.dispatch(
        setSlidingPanelData(
          {
            isShown: true,
            title: "Invoice Write-Off",
            children: <MatterInvoiceWriteOffForm
                matterId={matterId}
                matterInvoiceId={rowData.matterInvoiceId!}
              />
          }
        )
      );
      return;
    }
    default: { 
      return;
    }
  }
}

const abatementCallback = (matterId: string, rowData: MatterActivityHistoryModel) => {
  switch(rowData.activityType) { 
    case MatterActivityHistoryTypes.Invoice: { 
      store.dispatch(
        setSlidingPanelData(
          {
            isShown: true,
            title: "Invoice Abatement",
            children: <MatterInvoiceAbatementForm
                matterId={matterId}
                matterInvoiceId={rowData.matterInvoiceId!}
              />
          }
        )
      );
      return;
    }
    default: { 
      return;
    }
  }
}

const markAsDraftModal = (rowData: MatterActivityHistoryModel, triggerReloadPage: Function, syncMatterFiles: Function) => {
  let modal: ModalState = {
    title: "Mark as Draft confirmation",
    body: "Are you sure you want to mark as Draft this Matter Invoice?",
    actionText: "Mark as Draft",
    onAction: () => markAsDraftCallback(rowData.matterId, rowData.matterInvoiceId!, triggerReloadPage, syncMatterFiles),
    show: false
  }
  store.dispatch(setModalData(modal));
  store.dispatch(toggleModal());
};

const markAsDraftCallback = (matterId: string, matterInvoiceId: string, triggerReloadPage: Function, syncMatterFiles: Function) => {
  store.dispatch(toggleModalLoadingState());
  markMatterInvoiceAsDraft(matterId, matterInvoiceId).then(() => {
    // Reload Summary
    triggerReloadPage(false);
    // Sync matter files
    syncMatterFiles && syncMatterFiles();
    store.dispatch(toggleModal());
  })
  .catch((error) => {
    store.dispatch(setModalErrors(error.response?.data?.Message ?? error.message));
  })
  .finally(() => {
    store.dispatch(toggleModalLoadingState());
  });
};

const showInvoiceOnMatterStatisticsCardsModal = (rowData: MatterActivityHistoryModel, triggerReloadPage: Function) => {
  let modal: ModalState = {
    title: "Show On Statistics Cards confirmation",
    body: "Are you sure you want to show Invoice on Statistics Cards?",
    actionText: "Show on Statistics Cards",
    onAction: () => changeMatterInvoiceShowOnMatterStatisticsCardsCallback(rowData.matterId, rowData.matterInvoiceId!, triggerReloadPage),
    show: false
  }
  store.dispatch(setModalData(modal));
  store.dispatch(toggleModal());
};

const hideInvoiceFromMatterStatisticsCardsModal = (rowData: MatterActivityHistoryModel, triggerReloadPage: Function) => {
  let modal: ModalState = {
    title: "Hide From Statistics Cards confirmation",
    body: "Are you sure you want to hide Invoice from Statistics Cards?",
    actionText: "Hide from Statistics Cards",
    onAction: () => changeMatterInvoiceShowOnMatterStatisticsCardsCallback(rowData.matterId, rowData.matterInvoiceId!, triggerReloadPage),
    show: false
  }
  store.dispatch(setModalData(modal));
  store.dispatch(toggleModal());
};

const changeMatterInvoiceShowOnMatterStatisticsCardsCallback = (matterId: string, matterInvoiceId: string, triggerReloadPage: Function) => {
  store.dispatch(toggleModalLoadingState());
  changeMatterInvoiceShowOnMatterSummary(matterId, matterInvoiceId).then(() => {
    //reload the whole grid, because we might have RecordableEvents, IncidentalExpenses or Disbursements that changed by deleting the invoice
    triggerReloadPage();
    store.dispatch(toggleModal());
  })
  .catch((error) => {
    store.dispatch(setModalErrors(error.response?.data?.Message ?? error.message));
  })
  .finally(() => {
    store.dispatch(toggleModalLoadingState());
  });
};

export const navigationFilter = (rowData: MatterActivityHistoryModel) => {
  const loggedInUser = store.getState().user;

  const canManagePdfFiles = rowData.fileIsInvoiceRelated || 
    (loggedInUser.userRole?.name != UserRoleNames.Clerk &&
    loggedInUser.userRole?.name != UserRoleNames.BackOffice && 
    loggedInUser.userRole?.name != UserRoleNames.Accountant &&
    loggedInUser.userRole?.name != UserRoleNames.EntryLevel &&
    !rowData.fileIsInvoiceRelated);
        
  const canManageMails = rowData.mailIsCurrentUserSenderOrRecipient || 
    (loggedInUser.userRole?.name != UserRoleNames.Clerk &&
    loggedInUser.userRole?.name != UserRoleNames.BackOffice && 
    loggedInUser.userRole?.name != UserRoleNames.Accountant &&
    loggedInUser.userRole?.name != UserRoleNames.EntryLevel &&
    !rowData.mailIsCurrentUserSenderOrRecipient);

  const canView = (rowData.activityType == MatterActivityHistoryTypes.RecordableEvent &&
      loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ViewMatterRecordableEvents)) ||
    (rowData.activityType == MatterActivityHistoryTypes.Disbursement &&
      loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ViewMatterDisbursements)) ||
    (rowData.activityType == MatterActivityHistoryTypes.IncidentalExpense &&
      loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ViewMatterIncidentalExpenses)) ||
    (rowData.activityType == MatterActivityHistoryTypes.Invoice &&
      loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ViewMatterInvoices)) ||
    (rowData.activityType == MatterActivityHistoryTypes.File && 
      rowData.activitySubtype != MatterActivityHistorySubtypes.PDFFile &&
      loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatterActivityHistory)) ||
    (rowData.activityType == MatterActivityHistoryTypes.File && 
      rowData.activitySubtype == MatterActivityHistorySubtypes.PDFFile &&
      canManagePdfFiles) ||
    (rowData.activityType == MatterActivityHistoryTypes.Note) ||
    (rowData.activityType == MatterActivityHistoryTypes.Mail && canManageMails);

  const canPreviewFile = rowData.activityType == MatterActivityHistoryTypes.File && 
    rowData.activitySubtype != MatterActivityHistorySubtypes.OneNoteFile && 
    !rowData.fileIsCSV && !rowData.isFileDeleted && 
    ((rowData.activitySubtype != MatterActivityHistorySubtypes.PDFFile &&
      loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatterActivityHistory)) || 
      (rowData.activitySubtype == MatterActivityHistorySubtypes.PDFFile && canManagePdfFiles));
  
  const canPreviewEmail = rowData.activityType == MatterActivityHistoryTypes.Mail && canManageMails;

  return canView || canPreviewFile || canPreviewEmail;
}

export const getColumnDefs = (
  matterId: string,
  matterIsClosed: boolean,
  taxonomies: TaxonomyModel[],
  triggerReloadPage: Function,
  syncMatterFiles: Function,
  actions: {
    onDownload: (rows: MatterActivityHistoryModel[]) => void,
    onPrint: (rows: MatterActivityHistoryModel[]) => void,
    onCopy: (rows: MatterActivityHistoryModel[]) => void,
    onMove: (rows: MatterActivityHistoryModel[]) => void,
    onShare: (rows: MatterActivityHistoryModel[]) => void,
    onSendFiles: (rows: MatterActivityHistoryModel[]) => void,
    onConvertToMail: (rows: MatterActivityHistoryModel[]) => void,
  }
) => {
  const loggedInUser = store.getState().user;
  const buttonsColumnWidth = !matterIsClosed ? 105 : 65;
  let taxonomyChildrenColumnDefs: any[] = [];

  //defining taxonomy columns
  taxonomies.forEach((taxonomy: TaxonomyModel) => {
    taxonomyChildrenColumnDefs.push({
      headerName: taxonomy.displayName,
      field: 'taxonomyTerms',
      filter: DropdownArraySearchFilter,
      filterParams: {
        property: 'taxonomyTerms',
        suppressFilterButtons: false
      },
      floatingFilterComponent: DropdownFloatingFilter,
      floatingFilterComponentParams: {
        endpointCall: () => getMatterTaxonomyTermsSummary(matterId,taxonomy.id, true),
        showCheckedStrategy: TreeSelect.SHOW_ALL
      },
      cellRenderer: DropDownOptionListCellRenderer,
      valueFormatter: DropDownOptionListFormatter,
      valueGetter: (params: any) => {
        if(params.data && params.data['taxonomyTerms']) {
            return params.data['taxonomyTerms'].filter((x: TaxonomyTermModel) => x.taxonomyId == taxonomy.id)
              .map((x: TaxonomyTermModel) => ({name: x.displayNameWithFullSequence, id: x.id}));
        }
        return [];
      },
      sortable: true,
      minWidth: 220,
      enableRowGroup: true,
    });
  });

  const columnDefs: (ColDef<MatterActivityHistoryModel> | ColGroupDef<MatterActivityHistoryModel> | {excludeFromExport: boolean})[] = [
    { 
      headerName: 'Description',
      cellClass: 'lp-type-icon-cell',
      field: 'description',
      filter: 'agTextColumnFilter',
      cellRenderer: MatterActivityHistoryColumnWithSubtypeIconCellRenderer,
      minWidth: 400,
      suppressSizeToFit: true
    },
    { 
      headerName: 'DateTime',
      field: 'dateTime',
      filter: 'agDateColumnFilter',
      enableRowGroup: true,
      valueFormatter: DateTimeFormatterForMatterActivityHistory,
      filterParams: {
        comparator: dateComparator
      },
      minWidth: 190,
      width: 190,
      suppressSizeToFit: true
    },
    { 
      headerName: 'Author / Sender / Fee Earner',
      field: 'authorSenderFeeEarner',
      filter: 'agTextColumnFilter',
      enableRowGroup: true,
      minWidth: 150,
      width: 150,
    },
    { 
      headerName: 'Recipient',
      field: 'recipient',
      filter: 'agTextColumnFilter',
      enableRowGroup: true,
      minWidth: 150,
      width: 150
    },
    { 
      headerName: 'Activity Type',
      field: 'activitySubtype',
      enableRowGroup: true,
      cellRenderer: ActivityTypeCellRenderer,
      valueFormatter: MatterActivityHistorySubtypeFormatter,
      minWidth: 220,
      filter: DropdownFilter,
      filterParams: {
        property: 'activitySubtype',
        suppressFilterButtons: false,
      },
      floatingFilterComponent: DropdownFloatingFilter,
      floatingFilterComponentParams: {
        endpointCall: getMatterActivityHistorySubtypes
      }
    },
    {
      headerName: 'Is Billed',
      field: 'isBilled',
      enableRowGroup: true,
      minWidth: 120,
      width: 120,
      filter: BooleanFilter,
      filterParams: {
        property: 'isBilled',
        suppressFilterButtons: false,
      },
      floatingFilterComponent: BooleanFloatingFilter,
      floatingFilterComponentParams: {
        values: [{
          id: 'true',
          name: 'Yes'
        },
        {
          id: 'false',
          name: 'No'
        },
      ]},
      cellRenderer: CheckboxCellRenderer,
      valueFormatter: CheckboxFormatter
    },
    {
      headerName: 'Priority',
      field: 'priority',
      enableRowGroup: true,
      minWidth: 180,
      width: 180,
      valueFormatter: PriorityFormatter,
      filter: DropdownFilter,
      filterParams: {
        property: 'priority',
        suppressFilterButtons: false,
      },
      floatingFilterComponent: DropdownFloatingFilter,
      floatingFilterComponentParams: {
        endpointCall: () => Promise.resolve({data: getPriorities().map((x: any) => ({id: x.id.toString(), name: x.name}))})
      },
    },
    ...taxonomyChildrenColumnDefs,
    { 
      headerName: 'File Path',
      field: 'filePath',
      filter: 'agTextColumnFilter',
      minWidth: 150,
      width: 150,
    },
    { 
      headerName: 'Notes',
      field: 'additionalNote',
      filter: 'agTextColumnFilter',
      minWidth: 150,
      width: 150,
    },
    {
      headerName: 'Is Flagged',
      field: 'isFlagged',
      enableRowGroup: true,
      minWidth: 120,
      width: 120,
      filter: BooleanFilter,
      filterParams: {
        property: 'isFlagged',
        suppressFilterButtons: false,
      },
      floatingFilterComponent: BooleanFloatingFilter,
      floatingFilterComponentParams: {
        values: [{
          id: 'true',
          name: 'Yes'
        },
        {
          id: 'false',
          name: 'No'
        },
      ]},
      cellRenderer: FlagCellRenderer,
      hide: true,
    },
    { 
      excludeFromExport: true,
      cellClass: 'lp-actions-cell',
      suppressHeaderMenuButton: true,
      suppressColumnsToolPanel: true,
      cellRenderer: ActionsCellRenderer,
      cellRendererParams: (params: any) => {
        if(!params.data) {
          return { buttonsData: [] };
        }
        
        const canManagePdfFiles = params.data.fileIsInvoiceRelated || 
          (loggedInUser.userRole?.name != UserRoleNames.Clerk &&
          loggedInUser.userRole?.name != UserRoleNames.BackOffice && 
          loggedInUser.userRole?.name != UserRoleNames.Accountant &&
          loggedInUser.userRole?.name != UserRoleNames.EntryLevel &&
          !params.data.fileIsInvoiceRelated);
        
        const canManageMails = params.data.mailIsCurrentUserSenderOrRecipient || 
          (loggedInUser.userRole?.name != UserRoleNames.Clerk &&
          loggedInUser.userRole?.name != UserRoleNames.BackOffice && 
          loggedInUser.userRole?.name != UserRoleNames.Accountant &&
          loggedInUser.userRole?.name != UserRoleNames.EntryLevel &&
          !params.data.mailIsCurrentUserSenderOrRecipient);

        const canManageUserNotes = params.data.noteIsCreatedByCurrentUser || 
          (loggedInUser.userRole?.name != UserRoleNames.Lawyer &&
          loggedInUser.userRole?.name != UserRoleNames.Clerk &&
          loggedInUser.userRole?.name != UserRoleNames.BackOffice && 
          loggedInUser.userRole?.name != UserRoleNames.Accountant &&
          loggedInUser.userRole?.name != UserRoleNames.EntryLevel &&
          !params.data.noteIsCreatedByCurrentUser);

        const canView =
          (params.data.activityType == MatterActivityHistoryTypes.RecordableEvent &&
            loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ViewMatterRecordableEvents)) ||
          (params.data.activityType == MatterActivityHistoryTypes.Disbursement &&
            loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ViewMatterDisbursements)) ||
          (params.data.activityType == MatterActivityHistoryTypes.IncidentalExpense &&
            loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ViewMatterIncidentalExpenses)) ||
          (params.data.activityType == MatterActivityHistoryTypes.Invoice &&
            loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ViewMatterInvoices)) ||
          (params.data.activityType == MatterActivityHistoryTypes.File && 
            params.data.activitySubtype != MatterActivityHistorySubtypes.PDFFile &&
            loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatterActivityHistory)) ||
          (params.data.activityType == MatterActivityHistoryTypes.File && 
            params.data.activitySubtype == MatterActivityHistorySubtypes.PDFFile &&
            canManagePdfFiles) ||
          (params.data.activityType == MatterActivityHistoryTypes.Note) ||
          (params.data.activityType == MatterActivityHistoryTypes.Mail && canManageMails);

        const canPreviewFile = 
          params.data.activityType == MatterActivityHistoryTypes.File && 
          params.data.activitySubtype != MatterActivityHistorySubtypes.OneNoteFile && 
          !params.data.fileIsCSV && !params.data.isFileDeleted && 
          ((params.data.activitySubtype != MatterActivityHistorySubtypes.PDFFile &&
            loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatterActivityHistory)) || 
            (params.data.activitySubtype == MatterActivityHistorySubtypes.PDFFile && canManagePdfFiles));

        const canEditTaxonomies =
          (params.data.activityType == MatterActivityHistoryTypes.RecordableEvent &&
            loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatterRecordableEvents)) ||
          (params.data.activityType == MatterActivityHistoryTypes.Disbursement &&
            loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatterDisbursements)) ||
          (params.data.activityType == MatterActivityHistoryTypes.IncidentalExpense &&
            loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatterIncidentalExpenses)) ||
          (params.data.activityType == MatterActivityHistoryTypes.Invoice &&
            loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatterInvoices)) ||
          (params.data.activityType == MatterActivityHistoryTypes.File && 
            params.data.activitySubtype != MatterActivityHistorySubtypes.PDFFile &&
            loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatterActivityHistory)) ||
          (params.data.activityType == MatterActivityHistoryTypes.File && 
            params.data.activitySubtype == MatterActivityHistorySubtypes.PDFFile &&
            canManagePdfFiles) ||
          (params.data.activityType == MatterActivityHistoryTypes.Note &&
            params.data.activitySubtype == MatterActivityHistorySubtypes.SystemNote &&
            loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatterActivityHistory)) ||
          (params.data.activityType == MatterActivityHistoryTypes.Note &&
            params.data.activitySubtype == MatterActivityHistorySubtypes.UserNote && canManageUserNotes) ||
          (params.data.activityType == MatterActivityHistoryTypes.Mail && canManageMails);

        const canFlag =
          (params.data.activityType == MatterActivityHistoryTypes.RecordableEvent &&
            loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatterRecordableEvents)) ||
          (params.data.activityType == MatterActivityHistoryTypes.Disbursement &&
            loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatterDisbursements)) ||
          (params.data.activityType == MatterActivityHistoryTypes.IncidentalExpense &&
            loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatterIncidentalExpenses)) ||
          (params.data.activityType == MatterActivityHistoryTypes.Invoice &&
            loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatterInvoices)) ||
          (params.data.activityType == MatterActivityHistoryTypes.File && 
            params.data.activitySubtype != MatterActivityHistorySubtypes.PDFFile &&
            loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatterActivityHistory)) ||
          (params.data.activityType == MatterActivityHistoryTypes.File && 
            params.data.activitySubtype == MatterActivityHistorySubtypes.PDFFile &&
            canManagePdfFiles) ||
          (params.data.activityType == MatterActivityHistoryTypes.Note &&
            params.data.activitySubtype == MatterActivityHistorySubtypes.SystemNote &&
            loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatterActivityHistory)) ||
          (params.data.activityType == MatterActivityHistoryTypes.Note &&
            params.data.activitySubtype == MatterActivityHistorySubtypes.UserNote && canManageUserNotes) ||
          (params.data.activityType == MatterActivityHistoryTypes.Mail && canManageMails);

        const canChangePriority =
          (params.data.activityType == MatterActivityHistoryTypes.RecordableEvent &&
            loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatterRecordableEvents)) ||
          (params.data.activityType == MatterActivityHistoryTypes.Disbursement &&
            loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatterDisbursements)) ||
          (params.data.activityType == MatterActivityHistoryTypes.IncidentalExpense &&
            loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatterIncidentalExpenses)) ||
          (params.data.activityType == MatterActivityHistoryTypes.Invoice &&
            loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatterInvoices)) ||
          (params.data.activityType == MatterActivityHistoryTypes.File && 
            params.data.activitySubtype != MatterActivityHistorySubtypes.PDFFile &&
            loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatterActivityHistory)) ||
          (params.data.activityType == MatterActivityHistoryTypes.File && 
            params.data.activitySubtype == MatterActivityHistorySubtypes.PDFFile &&
            canManagePdfFiles) ||
          (params.data.activityType == MatterActivityHistoryTypes.Note &&
            params.data.activitySubtype == MatterActivityHistorySubtypes.SystemNote &&
            loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatterActivityHistory)) ||
          (params.data.activityType == MatterActivityHistoryTypes.Note &&
            params.data.activitySubtype == MatterActivityHistorySubtypes.UserNote && canManageUserNotes) ||
          (params.data.activityType == MatterActivityHistoryTypes.Mail && canManageMails);

        const canOpenFile = params.data.activityType == MatterActivityHistoryTypes.File && 
          ((params.data.activitySubtype != MatterActivityHistorySubtypes.PDFFile &&
            loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatterActivityHistory)) ||
          (params.data.activitySubtype == MatterActivityHistorySubtypes.PDFFile && canManagePdfFiles)) &&
          !params.data.isFileDeleted && params.data.fileWebUrl;
        
        const canDelete =
          (params.data.activityType == MatterActivityHistoryTypes.RecordableEvent &&
            loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatterRecordableEvents) &&
            !params.data.isBilled) ||
          (params.data.activityType == MatterActivityHistoryTypes.Disbursement &&
            loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatterDisbursements) &&
            !params.data.isBilled) ||
          (params.data.activityType == MatterActivityHistoryTypes.IncidentalExpense &&
            loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatterIncidentalExpenses) &&
            !params.data.isBilled) ||
          (params.data.activityType == MatterActivityHistoryTypes.Invoice &&
            loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatterInvoices)) ||
          (params.data.activityType == MatterActivityHistoryTypes.File && 
            params.data.activitySubtype != MatterActivityHistorySubtypes.PDFFile &&
            loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatterActivityHistory) &&
            !params.data.isFileDeleted) ||
          (params.data.activityType == MatterActivityHistoryTypes.File && 
            params.data.activitySubtype == MatterActivityHistorySubtypes.PDFFile &&
            !params.data.isFileDeleted && canManagePdfFiles) ||
          (params.data.activityType == MatterActivityHistoryTypes.Note &&
            params.data.activitySubtype == MatterActivityHistorySubtypes.UserNote && canManageUserNotes) ||
          (params.data.activityType == MatterActivityHistoryTypes.Mail && canManageMails);

        if(matterIsClosed) {
          return { buttonsData: [
            ...(canView
            ? [{
              type: ActionButtonTypes.View,
              callback: () => viewCallback(params.data, !!taxonomies?.length, syncMatterFiles)
            }] : [])
          ]};
        }
        else {
          return { buttonsData: [
            ...(canPreviewFile
            ? [{
              type: ActionButtonTypes.PreviewFile,
              callback: () => previewFileCallback(params.data, !!taxonomies?.length, syncMatterFiles)
            }] : []),
            ...(params.data.activityType == MatterActivityHistoryTypes.Mail && canManageMails
            ? [{
              type: ActionButtonTypes.PreviewEmail,
              callback: () => previewEmailCallback(params.data, !!taxonomies?.length, syncMatterFiles)
            }] : []),
            ...(canView
            ? [{
              type: ActionButtonTypes.View,
              callback: () => viewCallback(params.data, !!taxonomies?.length, syncMatterFiles)
            }] : []),

            ...((taxonomies.length > 0 && canEditTaxonomies)
            ? [{
              type: ActionButtonTypes.EditTaxonomyTerms,
              callback: () => editTaxonomyTermsCallback(params.data.matterId, params.data.id)
            }] : []),
            ...(canOpenFile
            ? [{
              type: ActionButtonTypes.OpenFile,
              callback: () => openUrlInNewtab(params.data.fileWebUrl)
            }] : []),
            ...(params.data.activityType == MatterActivityHistoryTypes.Mail && canManageMails
            ? [{
              type: ActionButtonTypes.SaveAttachments,
              callback: () => saveAttachmentsCallback(params.data.matterId, params.data.matterEmailId!, syncMatterFiles)
            }] : []),
            ...(params.data.activityType == MatterActivityHistoryTypes.IncidentalExpense && 
              loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatterIncidentalExpenses)
            ? [{
              type: ActionButtonTypes.LinkToRecordableEvent,
              callback: () => linkMatterIncidentalExpenseCallback(params.data.matterId, params.data.matterIncidentalExpenseId!)
            }] : []),
            ...(params.data.activityType == MatterActivityHistoryTypes.RecordableEvent &&
              loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatterIncidentalExpenses)
            ? [{
              type: ActionButtonTypes.UnlinkIncidentalExpenses,
              callback: () => unlinkMatterIncidentalExpenseCallback(params.data.matterId, params.data.matterRecordableEventId!)
            }] : []),
            ...((params.data.activityType == MatterActivityHistoryTypes.Disbursement &&
                loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ViewMatterDisbursements)) ||
              ((params.data.activityType == MatterActivityHistoryTypes.Invoice && 
                loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ViewMatterInvoices) &&
                params.data.invoiceStatusId != MatterInvoiceStatusesIds.DraftId))
            ? [{
              type: ActionButtonTypes.Payment,
              callback: () => paymentCallback(params.data.matterId, params.data)
            }] : []),
            ...((params.data.activityType == MatterActivityHistoryTypes.Invoice && 
              loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ViewMatterInvoices) &&
              params.data.invoiceStatusId == MatterInvoiceStatusesIds.AwaitingPaymentId)
            ? [{
              type: ActionButtonTypes.Abate,
              callback: () => abatementCallback(params.data.matterId, params.data)
            }] : []),
            ...((params.data.activityType == MatterActivityHistoryTypes.Disbursement &&
                loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ViewMatterDisbursements) &&
                !params.data.isBilled) ||
              (params.data.activityType == MatterActivityHistoryTypes.IncidentalExpense &&
                loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ViewMatterIncidentalExpenses) &&
                !params.data.isBilled) ||
              (params.data.activityType == MatterActivityHistoryTypes.Invoice &&
                loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ViewMatterInvoices) &&
                params.data.invoiceStatusId != MatterInvoiceStatusesIds.DraftId)
            ? [{
              type: ActionButtonTypes.WriteOff,
              callback: () => writeOffCallback(params.data.matterId, params.data)
            }] : []),
            ...((params.data.activityType == MatterActivityHistoryTypes.Invoice &&
              loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatterInvoices) &&
              params.data.invoiceStatusId == MatterInvoiceStatusesIds.AwaitingPaymentId)
            ? [{
              type: ActionButtonTypes.MarkAsDraft,
              callback: () => markAsDraftModal(params.data, triggerReloadPage, syncMatterFiles)
            }] : []),
            ...((params.data.activityType == MatterActivityHistoryTypes.Invoice &&
              loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatterInvoices) &&
              !params.data.invoiceShowOnMatterSummary)
            ? [{
              type: ActionButtonTypes.ShowOnMatterStatisticsCards,
              callback: () => showInvoiceOnMatterStatisticsCardsModal(params.data, triggerReloadPage)
            }] : []),
            ...((params.data.activityType == MatterActivityHistoryTypes.Invoice &&
              loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatterInvoices) &&
              params.data.invoiceShowOnMatterSummary)
            ? [{
              type: ActionButtonTypes.HideFromFromMatterStatisticsCards,
              callback: () => hideInvoiceFromMatterStatisticsCardsModal(params.data, triggerReloadPage)
            }] : []),

            ...((loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatterActivityHistory) && !params.data.isFileDeleted) ? [
              {
                type: ActionButtonTypes.Download,
                callback: () => actions.onDownload([params.data])
              },
              {
                type: ActionButtonTypes.Print,
                callback: () => actions.onPrint([params.data])
              },
              ...(params.data.activityType === MatterActivityHistoryTypes.Note
                || params.data.activityType === MatterActivityHistoryTypes.File
                || params.data.activityType === MatterActivityHistoryTypes.Mail ? [
                {
                  type: ActionButtonTypes.Copy,
                  callback: () => actions.onCopy([params.data])
                }
              ] : []),
              ...(params.data.activityType === MatterActivityHistoryTypes.Note
                || params.data.activityType === MatterActivityHistoryTypes.IncidentalExpense
                || params.data.activityType === MatterActivityHistoryTypes.Disbursement
                || params.data.activityType === MatterActivityHistoryTypes.RecordableEvent
                || params.data.activityType === MatterActivityHistoryTypes.File
                || params.data.activityType === MatterActivityHistoryTypes.Mail ? [
                {
                  type: ActionButtonTypes.Move,
                  callback: () => actions.onMove([params.data])
                },
              ] : []),
              ...(params.data.activityType === MatterActivityHistoryTypes.File
                || params.data.activityType === MatterActivityHistoryTypes.Mail ? [
                {
                  type: ActionButtonTypes.Share,
                  callback: () => actions.onShare([params.data])
                },
              ] : []),
              ...(params.data.activityType === MatterActivityHistoryTypes.File ? [
                {
                  type: ActionButtonTypes.SendFiles,
                  callback: () => actions.onSendFiles([params.data])
                },
              ] : []),
              ...(params.data.activityType === MatterActivityHistoryTypes.File
                && params.data.activitySubtype === MatterActivityHistorySubtypes.EmailFile ? [
                {
                  type: ActionButtonTypes.ConvertToMail,
                  callback: () => actions.onConvertToMail([params.data])
                },
              ] : []),
            ] : []),

            ...(canFlag ? [
              params.data.isFlagged ? {
                type: ActionButtonTypes.Unflag,
                callback: () => unflagModal(params.data.matterId, params.data.id)
              } : {
                type: ActionButtonTypes.Flag,
                callback: () => flagModal(params.data.matterId, params.data.id)
              }
            ] : []),
            ...(canChangePriority ? [
              ...(params.data.priority !== Priority.High ? [{
                type: ActionButtonTypes.IncreasePriority,
                callback: () => increasePriorityModal(params.data.matterId, params.data.id, triggerReloadPage)
              }] : []),
              ...(params.data.priority !== Priority.Low ? [{
                type: ActionButtonTypes.DecreasePriority,
                callback: () => decreasePriorityModal(params.data.matterId, params.data.id, triggerReloadPage)
              }] : []),
            ] : []),
            ...(canDelete
            ? [{
              type: ActionButtonTypes.Delete,
              callback: () => deleteModal(params.data, triggerReloadPage, syncMatterFiles)
            }] : []),
          ]}
        }
      },
      suppressSizeToFit: true,
      floatingFilter: false,
      sortable: false,
      minWidth: buttonsColumnWidth,
      maxWidth: buttonsColumnWidth,
      width: buttonsColumnWidth,
      suppressMovable: true,
      pinned: 'right',
      resizable: false
    }
  ];

  return columnDefs;
}