import Tippy from "@tippyjs/react";
import { Button } from "react-bootstrap";
import { MdError } from "react-icons/md";
import "./style.scss";

type Props = {
  text: string;
  variant?: string;
}

export default function FormErrorButton(props: Props) {
  return (
    <Tippy
      content="Some required fields are missing. Please fill in all the mandatory fields marked with an asterisk (*) to proceed."
      placement="top-start"
      className="lp-form-error-tooltip"
      appendTo={document.body}
      interactive={true}
    >
      <div className="lp-form-error-button">
        <Button variant={props.variant} type="submit" disabled>
          <MdError />
          {props.text}
        </Button>
      </div>
    </Tippy>
  );
}

FormErrorButton.defaultProps = {
  variant: 'success'
}