import React, { cloneElement, ReactElement, useEffect, useState } from "react";
import Field from "components/Fields/Field";
import Loader from "components/Loader";
import { Row, Col, Button } from "react-bootstrap";
import {
  changeMatterInvoiceShowOnMatterSummary,
  createMatterInvoiceDocument,
  createMatterInvoiceReceipt,
  decreasePriorityMatterActivityHistory,
  deleteMatterInvoice,
  downloadActivityHistory,
  flagMatterActivityHistory,
  getMatterDisbursementsForInvoice,
  getMatterIncidentalExpensesForInvoice,
  getMatterInvoiceById,
  getMatterRecordableItemsForInvoice,
  increasePriorityMatterActivityHistory,
  markMatterInvoiceAsDraft,
  printActivityHistory,
  unflagMatterActivityHistory
} from "actions/matter";
import moment from "moment";
import { DateFormat } from "utils/constants";
import { MatterInvoiceModel } from "models/view/MatterInvoiceModel";
import { downloadFile, formatCurrency, getDecreasedPriority, getIncreasedPriority, printPdf } from "utils/misc";
import { MatterInvoiceStatusesIds } from "enums/MatterInvoiceStatusesIds";
import useSlidingPanelActions from "actions/slidingPanel";
import useModalActions from "actions/modal";
import { ModalState } from "state/modalSlice";
import ViewMatterInvoicePayments from "../ViewMatterInvoicePayments/ViewMatterInvoicePayments";
import { useAppSelector } from "hooks/appSelector";
import EditMatterInvoiceForm from "../EditMatterInvoice/EditMatterInvoiceForm";
import { GridIds } from "enums/GridIds";
import PreviewMatterFile from "../../MatterFile/PreviewMatterFile/PreviewMatterFile";
import MatterInvoiceWriteOffForm from "../MatterInvoiceWriteOff/MatterInvoiceWriteOffForm";
import InvoiceStatusField from "components/Fields/InvoiceStatusField";
import BooleanField from "components/Fields/BooleanField";
import MatterInvoiceAbatementForm from "../MatterInvoiceAbatement/MatterInvoiceAbatementForm";
import { MatterRecordableItemModel } from "models/view/MatterRecordableItemModel";
import { MatterIncidentalExpenseModel } from "models/view/MatterIncidentalExpenseModel";
import { MatterDisbursementModel } from "models/view/MatterDisbursementModel";
import { MatterRecordableItemForInvoiceModel } from "models/view/MatterRecordableItemForInvoiceModel";
import currency from "currency.js";
import { MatterIncidentalExpenseForInvoiceModel } from "models/view/MatterIncidentalExpenseForInvoiceModel";
import ViewMatterInvoiceRecordableItems from "../ViewMatterInvoiceItemList/ViewMatterInvoiceRecordableItems";
import ViewMatterInvoiceIncidentalExpenses from "../ViewMatterInvoiceItemList/ViewMatterInvoiceIncidentalExpenses";
import ViewMatterInvoiceDisbursements from "../ViewMatterInvoiceItemList/ViewMatterInvoiceDisbursements";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import EditActivityHistoryTaxonomyTermsForm from "../../ActivityHistoryGrid/ActivityHistoryTaxonomyTerms/EditActivityHistoryTaxonomyTerms/EditActivityHistoryTaxonomyTermsForm";
import { UserPermissionsNames } from "enums/UserPermissionsNames";
import usePageActions from "actions/page";
import { Priority } from "enums/Priority";
import { getPriority } from "utils/enums";
import useNotificationActions from "actions/notification";
import { NotificationTypes } from "enums/NotificationTypes";

type Props = {
  matterId: string,
  matterInvoiceId: string,
  showDocumentActions?: boolean,
  activityHistoryId?: string,
  isMatterClosed?: boolean,
  hasTaxonomies?: boolean,
  syncMatterFiles?: Function,
  showMoreButtons?: boolean,
  isFlagged?: boolean,
  priority?: Priority,
}

function ViewMatterInvoice(props: Props){
  const [isLoading, setIsLoading] = useState(true);
  const [matterInvoice, setMatterInvoice] = useState<MatterInvoiceModel>();
  const [matterInvoiceRecordableItems, setMatterInvoiceRecordableItems] = useState<MatterRecordableItemForInvoiceModel[]>([]);
  const [matterInvoiceIncidentalExpenses, setMatterInvoiceIncidentalExpenses] = useState<MatterIncidentalExpenseForInvoiceModel[]>([]);
  const [matterInvoiceDisbursements, setMatterInvoiceDisbursements] = useState<MatterDisbursementModel[]>([]);
  const [genericErrors, setGenericErrors] = useState<string | null>(null);
  const [isFlagged, setIsFlagged] = useState<boolean | undefined>(props.isFlagged);
  const [priority, setPriority] = useState<Priority | undefined>(props.priority);
  const slidingPanelActions = useSlidingPanelActions();
  const modalActions = useModalActions();
  const currentSlidingPanelState = useAppSelector((state) => state.slidingPanel);
  const loggedInUser = useAppSelector((state) => state.user);
  const gridState = useAppSelector((state) => state.grid);
  const pageActions = usePageActions();
  const notificationActions = useNotificationActions();

  useEffect(() => {
    // When moving between multiple items of the same type, update local state for flag and priority
    setIsFlagged(props.isFlagged);
    setPriority(props.priority);

    loadMatterInvoice();
  }, [props.matterInvoiceId]);

  useEffect(() => {
    if (matterInvoice) {
      setSlidingPanelEditButton();
      setSlidingPanelMoreButtons();
    }
  }, [matterInvoice, isFlagged, priority]);

  const loadMatterInvoice = () => {
    setIsLoading(true);

    const promises = [
      getMatterInvoiceById(props.matterId, props.matterInvoiceId),
      getMatterRecordableItemsForInvoice(props.matterId, props.matterInvoiceId),
      getMatterIncidentalExpensesForInvoice(props.matterId, props.matterInvoiceId),
      getMatterDisbursementsForInvoice(props.matterId, props.matterInvoiceId),
    ];

    Promise.all(promises)
      .then(([invoiceResponse, recordableItemsResponse, incidentalExpensesResponse, disbursementsResponse]) => {
        const currentVATRate = invoiceResponse.data.vatRate?.vatRateValue;

        setMatterInvoice(invoiceResponse.data);

        const recordableItemsForInvoice = recordableItemsResponse.data.map((x: MatterRecordableItemModel) => ({
          ...x,
          calculatedVatValue: x.calculatedVATValueOnInvoice ? x.calculatedVATValueOnInvoice : currency(x.amountCharged * currentVATRate / 100).value,
          adjustmentAmountCharged: x.adjustedAmountChargedOnInvoice ? currency(x.adjustedAmountChargedOnInvoice).subtract(x.amountCharged).value : 0
        }));
        setMatterInvoiceRecordableItems(recordableItemsForInvoice);

        const incidentalExpensesForInvoice = incidentalExpensesResponse.data.map((x: MatterIncidentalExpenseModel) => ({
          ...x,
          netValue: currency(x.netValue - (x.netValue / x.grossValue * (x.writeOffAmount ?? 0))).value,
          vatValue: x.vatValue ? currency(x.vatValue - (x.vatValue / x.grossValue * (x.writeOffAmount ?? 0))).value : undefined,
          originalVATValue: x.vatValue,
          calculatedVatValue: x.vatValueOnInvoice ? currency(x.vatValueOnInvoice - (x.vatValueOnInvoice / x.grossValue * (x.writeOffAmount ?? 0))).value
            : (x.vatValue ? 0 : currency((x.netValue - (x.writeOffAmount ?? 0)) * currentVATRate / 100).value)
        }));
        setMatterInvoiceIncidentalExpenses(incidentalExpensesForInvoice);

        const disbursementsForInvoice = disbursementsResponse.data.map((x: MatterDisbursementModel) => ({
          ...x,
          netValue: currency(x.netValue - (x.netValue / x.grossValue * (x.writeOffAmount ?? 0))).value,
          vatValue: x.vatValue ? currency(x.vatValue - (x.vatValue / x.grossValue * (x.writeOffAmount ?? 0))).value : undefined
        }));
        setMatterInvoiceDisbursements(disbursementsForInvoice);
      }).catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      }).finally(() => {
        setIsLoading(false);
      });
  }

  const setSlidingPanelEditButton = () => {
    if(loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatterInvoices) &&
      !matterInvoice?.matter?.isClosed && matterInvoice?.matterInvoiceStatusId === MatterInvoiceStatusesIds.DraftId) {
      slidingPanelActions.setOnEdit(() => {
        slidingPanelActions.clearSlidingPanel();
  
        slidingPanelActions.setSlidingPanel(
        {
          isShown: true,
          title: "Edit Matter Invoice",
          children: <EditMatterInvoiceForm
            matterId={props.matterId}
            matterInvoiceId={props.matterInvoiceId}
            syncMatterFiles={props.syncMatterFiles}
            onSubmitCallback={onChildPanelClose}
          />,
          onCancel: onChildPanelClose
        });
      });
    }
  }

  const setSlidingPanelMoreButtons = () => {
    if (!matterInvoice?.matter?.isClosed && props.showMoreButtons) {
      slidingPanelActions.setMoreButtons([
        // Taxonomies
        ...((loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatterInvoices) && 
          props.hasTaxonomies && props.activityHistoryId) ? [{
            type: ActionButtonTypes.EditTaxonomyTerms,
            callback: () => onClickTaxonomies(),
        }] : []),
        // Mark as draft
        ...(loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatterInvoices) && 
          matterInvoice?.matterInvoiceStatusId === MatterInvoiceStatusesIds.AwaitingPaymentId ? [{
            type: ActionButtonTypes.MarkAsDraft,
            callback: () => onClickMarkAsDraft(),
        }] : []),
        // WriteOff & Payment
        ...(matterInvoice?.matterInvoiceStatusId !== MatterInvoiceStatusesIds.DraftId ? [
          {
            type: ActionButtonTypes.WriteOff,
            callback: () => onClickWriteOff(),
          },
          {
            type: ActionButtonTypes.Payment,
            callback: () => onClickRecordPayment(),
          },
        ] : []),
        // Abatement
        ...(matterInvoice?.matterInvoiceStatusId === MatterInvoiceStatusesIds.AwaitingPaymentId ? [{
            type: ActionButtonTypes.Abate,
            callback: () => onClickAbatement(),
        }] : []),
        // Show on matter statistics cards
        ...((loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatterInvoices) && 
          props.activityHistoryId && !matterInvoice?.showOnMatterSummary) ? [{
            type: ActionButtonTypes.ShowOnMatterStatisticsCards,
            callback: () => showInvoiceOnMatterStatisticsCardsModal()
        }] : []),
        // Hide from matter statistics cards
        ...((loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatterInvoices) && 
          props.activityHistoryId && matterInvoice?.showOnMatterSummary) ? [{
            type: ActionButtonTypes.HideFromFromMatterStatisticsCards,
            callback: () => hideInvoiceFromMatterStatisticsCardsModal()
        }] : []),
        // Actions
        ...(loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatterActivityHistory) ? [
          {
            type: ActionButtonTypes.Download,
            callback: () => downloadItem(props.matterId, props.activityHistoryId!)
          },
          {
            type: ActionButtonTypes.Print,
            callback: () => printItem(props.matterId, props.activityHistoryId!)
          },
        ] : []),
        // Flag
        ...((loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatterInvoices) &&
          props.activityHistoryId && isFlagged !== undefined) ? [
          isFlagged ? {
            type: ActionButtonTypes.Unflag,
            callback: () => unflagModal(props.matterId, props.activityHistoryId!)
          } : {
            type: ActionButtonTypes.Flag,
            callback: () => flagModal(props.matterId, props.activityHistoryId!)
          },
        ] : []),
        // Priority
        ...((loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatterInvoices) &&
          props.activityHistoryId && priority !== undefined) ? [
          ...(priority !== Priority.High ? [{
            type: ActionButtonTypes.IncreasePriority,
            callback: () => increasePriorityModal(props.matterId, props.activityHistoryId!)
          }] : []),
          ...(priority !== Priority.Low ? [{
            type: ActionButtonTypes.DecreasePriority,
            callback: () => decreasePriorityModal(props.matterId, props.activityHistoryId!)
          }] : []),
        ] : []),
        // Delete
        ...(loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatterInvoices) ? [{
          type: ActionButtonTypes.Delete,
          callback: () => onClickDelete(),
        }] : [])
      ]);
    }
  }

  const onChildPanelClose = () => {
    slidingPanelActions.clearSlidingPanel();
    slidingPanelActions.setSlidingPanel({
      ...currentSlidingPanelState,
      children: cloneElement(currentSlidingPanelState.children! as ReactElement, {priority: priority, isFlagged: isFlagged}),
    });
  };

  const flagModal = (matterId: string, activityId: string) => {
    let modal: ModalState = {
      title: "Flag confirmation",
      body: "Are you sure you want to add a flag?",
      actionText: "Flag",
      onAction: () => flagCallback(matterId, activityId),
      show: false
    }
    modalActions.setModal(modal);
    modalActions.toggleModalShownStatus(); 
  }

  const flagCallback = (matterId: string, activityId: string) => {
    modalActions.toggleModalLoading();
    flagMatterActivityHistory(matterId, activityId).then(() => {
      pageActions.triggerReloadPage(true, false);
      setIsFlagged(true); // Local state flag update - used to identify which button to show
      modalActions.toggleModalShownStatus(); 
    })
    .catch((error) => {
      modalActions.setErrorsForModal(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      modalActions.toggleModalLoading();
    });
    return;
  }

  const unflagModal = (matterId: string, activityId: string) => {
    let modal: ModalState = {
      title: "Unflag confirmation",
      body: "Are you sure you want to remove this flag?",
      actionText: "Unflag",
      onAction: () => unflagCallback(matterId, activityId),
      show: false
    }
    modalActions.setModal(modal);
    modalActions.toggleModalShownStatus(); 
  }

  const unflagCallback = (matterId: string, activityId: string) => {
    modalActions.toggleModalLoading();
    unflagMatterActivityHistory(matterId, activityId).then(() => {
      pageActions.triggerReloadPage(true, false);
      setIsFlagged(false); // Local state flag update - used to identify which button to show
      modalActions.toggleModalShownStatus(); 
    })
    .catch((error) => {
      modalActions.setErrorsForModal(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      modalActions.toggleModalLoading();
    });
    return;
  }

  const increasePriorityModal = (matterId: string, activityId: string) => {
    let modal: ModalState = {
      title: "Increase priority confirmation",
      body: "Are you sure you want to increase the priority?",
      actionText: "Increase",
      onAction: () => increasePriorityCallback(matterId, activityId),
      show: false
    }
    modalActions.setModal(modal);
    modalActions.toggleModalShownStatus(); 
  }

  const increasePriorityCallback = (matterId: string, activityId: string) => {
    modalActions.toggleModalLoading();
    increasePriorityMatterActivityHistory(matterId, activityId).then(() => {
      pageActions.triggerReloadPage(true, false);
      setPriority(getIncreasedPriority(priority!)); // Local state priority update - used to identify which buttons to show
      modalActions.toggleModalShownStatus(); 
    })
    .catch((error) => {
      modalActions.setErrorsForModal(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      modalActions.toggleModalLoading();
    });
    return;
  }

  const decreasePriorityModal = (matterId: string, activityId: string) => {
    let modal: ModalState = {
      title: "Decrease priority confirmation",
      body: "Are you sure you want to decrease the priority?",
      actionText: "Decrease",
      onAction: () => decreasePriorityCallback(matterId, activityId),
      show: false
    }
    modalActions.setModal(modal);
    modalActions.toggleModalShownStatus(); 
  }

  const decreasePriorityCallback = (matterId: string, activityId: string) => {
    modalActions.toggleModalLoading();
    decreasePriorityMatterActivityHistory(matterId, activityId).then(() => {
      pageActions.triggerReloadPage(true, false);
      setPriority(getDecreasedPriority(priority!)); // Local state priority update - used to identify which buttons to show
      modalActions.toggleModalShownStatus(); 
    })
    .catch((error) => {
      modalActions.setErrorsForModal(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      modalActions.toggleModalLoading();
    });
    return;
  }

  const onClickTaxonomies = () => {
    slidingPanelActions.clearSlidingPanel();

    slidingPanelActions.setSlidingPanel(
      {
        isShown: true,
        title: "Edit Taxonomy Terms",
        children: <EditActivityHistoryTaxonomyTermsForm
          matterId={props.matterId}
          activityHistoryId={props.activityHistoryId!}
          onSubmitCallback={onChildPanelClose}
        />,
        onCancel: onChildPanelClose
      }
    );
  };

  const showInvoiceOnMatterStatisticsCardsModal = () => {
    let modal: ModalState = {
      title: "Show On Statistics Cards confirmation",
      body: "Are you sure you want to show Invoice on Statistics Cards?",
      actionText: "Show on Statistics Cards",
      onAction: () => changeMatterInvoiceShowOnMatterStatisticsCardsCallback(),
      show: false
    }
    modalActions.setModal(modal);
    modalActions.toggleModalShownStatus();
  };

  const hideInvoiceFromMatterStatisticsCardsModal = () => {
    let modal: ModalState = {
      title: "Hide From Statistics Cards confirmation",
      body: "Are you sure you want to hide Invoice from Statistics Cards?",
      actionText: "Hide from Statistics Cards",
      onAction: () => changeMatterInvoiceShowOnMatterStatisticsCardsCallback(),
      show: false
    }
    modalActions.setModal(modal);
    modalActions.toggleModalShownStatus();
  };

  const changeMatterInvoiceShowOnMatterStatisticsCardsCallback = () => {
    modalActions.toggleModalLoading();
    changeMatterInvoiceShowOnMatterSummary(props.matterId, props.matterInvoiceId).then(() => {
      setMatterInvoice({ ...matterInvoice!, showOnMatterSummary: !matterInvoice?.showOnMatterSummary });
      props.syncMatterFiles && props.syncMatterFiles();
      modalActions.toggleModalShownStatus();
    })
    .catch((error) => {
      modalActions.setErrorsForModal(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      modalActions.toggleModalLoading();
    });
  };

  const onClickCreateDocument = (detailedDocument: boolean = false) => {
    setIsLoading(true);
    createMatterInvoiceDocument(props.matterId, props.matterInvoiceId, detailedDocument).then(() => {
      loadMatterInvoice();

      if (gridState.id === GridIds.ActivityHistory) {
        pageActions.triggerReloadPage();
      }
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });  
  }

  const onClickCreateReceipt = () => {
    setIsLoading(true);
    createMatterInvoiceReceipt(props.matterId, props.matterInvoiceId).then(() => {
      loadMatterInvoice();

      if (gridState.id === GridIds.ActivityHistory) {
        pageActions.triggerReloadPage();
      }
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });  
  }

  function onClickViewDocument(fileId: string) {
    //close View Matter Invoice sliding panel
    slidingPanelActions.clearSlidingPanel();
    //open Preview Matter File sliding panel having onCancel event to reopen 
    //Preview Matter File sliding panel
    slidingPanelActions.setSlidingPanel(
    {
      isShown: true,
      title: "Preview Matter File",
      children: <PreviewMatterFile
        matterId={props.matterId}
        fileId={fileId}
        activityHistoryId={props.activityHistoryId}
        isMatterClosed={props.isMatterClosed}
        syncMatterFiles={props.syncMatterFiles}
        hasTaxonomies={props.hasTaxonomies ?? false}
      />,
      onCancel: onChildPanelClose
    });
  }

  function onClickMarkAsDraft() {
    setIsLoading(true);
    let modal: ModalState = {
      title: "Mark as Draft confirmation",
      body: "Are you sure you want to mark as Draft this Matter Invoice?",
      actionText: "Mark as Draft",
      onAction: () => markAsDraftCallback(props.matterId, props.matterInvoiceId),
      onClose: () => {setIsLoading(false);},
      show: false
    }
    modalActions.setModal(modal);
    modalActions.toggleModalShownStatus();  
  }

  function markAsDraftCallback(matterId: string, matterInvoiceId: string) {
    modalActions.toggleModalLoading();
    markMatterInvoiceAsDraft(matterId, matterInvoiceId).then(() => {
      slidingPanelActions.clearSlidingPanel();
      modalActions.toggleModalShownStatus();

      if(props.syncMatterFiles) {
        // Reload summary
        pageActions.triggerReloadPage(false);
        // Call sync matter files
        props.syncMatterFiles && props.syncMatterFiles();
      }
      else {
        // Reload entire page
        pageActions.triggerReloadPage();
      }
    })
    .catch((error) => {
      modalActions.setErrorsForModal(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      modalActions.toggleModalLoading();
      setIsLoading(false);
    });
  }

  function onClickDelete() {
    setIsLoading(true);
    let modal: ModalState = {
      title: "Delete confirmation",
      body: "Are you sure you want to delete this Matter Invoice?",
      actionText: "Delete",
      actionVariant: "danger",
      onAction: () => deleteCallback(props.matterId, props.matterInvoiceId),
      onClose: () => {setIsLoading(false);},
      show: false
    }
    modalActions.setModal(modal);
    modalActions.toggleModalShownStatus();  
  }

  const deleteCallback = (matterId: string, matterInvoiceId: string) => {
    modalActions.toggleModalLoading();
    deleteMatterInvoice(matterId, matterInvoiceId).then(() => {
      slidingPanelActions.clearSlidingPanel();
      modalActions.toggleModalShownStatus(); 

      if(props.syncMatterFiles) {
        // Reload summary
        pageActions.triggerReloadPage(false);
        // Call sync matter files
        props.syncMatterFiles && props.syncMatterFiles();
      }
      else {
        // Reload entire page
        pageActions.triggerReloadPage();
      }
    })
    .catch((error) => {
      modalActions.setErrorsForModal(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      modalActions.toggleModalLoading();
      setIsLoading(false);
    });
  };

  function onClickRecordPayment() {
    //close View Matter Invoice sliding panel
    slidingPanelActions.clearSlidingPanel();
    //open View Matter Invoice Payments sliding panel having onCancel event to reopen 
    //View Matter Invoice Payments sliding panel
    slidingPanelActions.setSlidingPanel(
    {
      isShown: true,
      title: "Invoice Payments",
      children: <ViewMatterInvoicePayments
          matterId={props.matterId}
          matterInvoiceId={props.matterInvoiceId}
        />,
      onCancel: onChildPanelClose
    });
  }

  function onClickWriteOff() {
    //close View Matter Invoice sliding panel
    slidingPanelActions.clearSlidingPanel();
    //open View Matter Invoice WriteOff sliding panel having onCancel event to reopen 
    //View Matter Invoice sliding panel
    slidingPanelActions.setSlidingPanel(
    {
      isShown: true,
      title: "Invoice Write-Off",
      children: <MatterInvoiceWriteOffForm
          matterId={props.matterId}
          matterInvoiceId={props.matterInvoiceId}
          onSubmitCallback={onChildPanelClose}
          onDeleteCallback={onChildPanelClose}
        />,
      onCancel: onChildPanelClose
    });
  }

  function onClickAbatement() {
    //close View Matter Invoice sliding panel
    slidingPanelActions.clearSlidingPanel();
    //open View Matter Invoice Abatement sliding panel having onCancel event to reopen 
    //View Matter Invoice sliding panel
    slidingPanelActions.setSlidingPanel(
    {
      isShown: true,
      title: "Invoice Abatement",
      children: <MatterInvoiceAbatementForm
          matterId={props.matterId}
          matterInvoiceId={props.matterInvoiceId}
          onSubmitCallback={onChildPanelClose}
          onDeleteCallback={onChildPanelClose}
        />,
      onCancel: onChildPanelClose
    });
  }

  const downloadItem = (matterId: string, activityHistoryId: string) => {
    setIsLoading(true);

    downloadActivityHistory(matterId, [activityHistoryId]).then((response) => {
      downloadFile(response);
    })
    .catch((error) => {
      error.response.data.text().then((text: any) => {
        let responseData = undefined;
        try {
          responseData = JSON.parse(text);
        }
        catch(e) {
          responseData = undefined;
        }

        notificationActions.addNotificationMessage({
          type: NotificationTypes.Error,
          title: "Download Error",
          body: `${responseData?.Message ?? "There was an error while downloading the file."}`,
          isDismissable: true
        });
      });
    })
    .finally(() => {
      setIsLoading(false);
    });
  }

  const printItem = (matterId: string, activityHistoryId: string) => {
    setIsLoading(true);

    printActivityHistory(matterId, [activityHistoryId]).then((response) => {
      printPdf(response.data);
    })
    .catch((error) => {
      //the response is Blob here, we need to parse it
      error.response.data.text().then((text: any) => {
        let responseData = undefined;
        try {
          responseData = JSON.parse(text);
        }
        catch(e) {
          responseData = undefined;
        }

        notificationActions.addNotificationMessage({
          type: NotificationTypes.Error,
          title: "Print Error",
          body: `${responseData?.Message ?? "There was an error printing the file."}`,
          isDismissable: true
        });
      });
    })
    .finally(() => {
      setIsLoading(false);
    });
  }

  return(
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <div className="d-flex flex-column h-100">
        <Row>
          <Col>
            <Field
              label={"Clients"}
              value={matterInvoice?.matterParticipatingEntities?.map(x => x.name)?.join(", ") ?? "—"}
            />
          </Col>
          <Col>
            <Field
              label={"Invoicing Party"}
              value={matterInvoice?.invoicingParty?.name}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Field
              label={"Date"}
              value={matterInvoice?.date ? moment(matterInvoice?.date).format(DateFormat.Moment) : "—"}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Field
              label={"Invoice Ref Number"}
              value={matterInvoice?.invoiceRefNumber}
            />
          </Col>
          <Col>
            <Field
              label={"Display Name"}
              value={matterInvoice?.displayName}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Field
              label={"Description"}
              value={matterInvoice?.description}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            {matterInvoice?.matterInvoiceStatus?.name ?
              <InvoiceStatusField
                label={"Status"}
                value={matterInvoice?.matterInvoiceStatus}
              />
              :
              <Field
                label={"Status"}
                value={"—"}
              />
            }
          </Col>
          <Col>
            <Field
              label={"VAT Rate"}
              value={matterInvoice?.vatRate?.name}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Field
              label={"Amount Paid"}
              value={formatCurrency(matterInvoice?.amountPaid)}
            />
          </Col>
          <Col>
            <Field
              label={"Amount Due"}
              value={formatCurrency(matterInvoice?.amountDue)}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Field
              label={"Due Date"}
              value={matterInvoice?.dueDate ? moment(matterInvoice?.dueDate).format(DateFormat.Moment) : "—"}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <BooleanField
              label={'Show On Matter Summary'}
              value={matterInvoice?.showOnMatterSummary ?? false}
            />
          </Col>
        </Row>

        {matterInvoice?.writeOffAmount &&
          <>
            <Row>
              <Col>
                <Field
                  label={"Write-Off Date"}
                  value={matterInvoice?.writeOffDate ? moment(matterInvoice?.writeOffDate).format(DateFormat.Moment) : "—"}
                />
              </Col>
              <Col>
                <Field
                  label={"Write-Off Amount"}
                  value={matterInvoice?.writeOffAmount ? formatCurrency(matterInvoice?.writeOffAmount) : "—"}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Field
                  label={"Write-Off Description"}
                  value={matterInvoice?.writeOffDescription}
                />
              </Col>
            </Row>
            {matterInvoice?.writeOffForSingleClient &&
              <Row>
                <Col>
                  <Field
                    label={"Write-Off Client"}
                    value={matterInvoice?.writeOffMatterParticipatingEntity?.name ?? "—"}
                  />
                </Col>
              </Row>
            }
          </>
        }

        { isFlagged !== undefined || priority !== undefined ?
          <Row>
            { isFlagged !== undefined &&
              <Col sm={6}>
                <BooleanField
                  label={"Is Flagged"}
                  value={isFlagged ?? false}
                />
              </Col>
            }
            { priority !== undefined &&
              <Col sm={6}>
                <Field
                  label={"Priority"}
                  value={priority !== undefined ? getPriority(priority) : "—"}
                />
              </Col>
            }
          </Row>
        : null }

        <div className="lp-color-title primary full-width mt-4">Recordable Items</div>
        <ViewMatterInvoiceRecordableItems
          isLoading={isLoading}
          items={matterInvoiceRecordableItems}
        />

        <div className="lp-color-title primary full-width">Incidental Expenses</div>
        <ViewMatterInvoiceIncidentalExpenses
          isLoading={isLoading}
          items={matterInvoiceIncidentalExpenses}
        />

        <div className="lp-color-title primary full-width">Disbursements</div>
        <ViewMatterInvoiceDisbursements
          isLoading={isLoading}
          items={matterInvoiceDisbursements}
        />

        <div className="lp-color-title primary full-width">Total Values</div>
        <Row>
          <Col className="mb-4">
            <Field
              label={"Gross Value"}
              value={formatCurrency(matterInvoice?.totalGrossValue)}
            />
          </Col>
          <Col className="mb-4">
            <Field
              label={"VAT Value"}
              value={formatCurrency(matterInvoice?.totalVATValue)}
            />
          </Col>
          <Col className="mb-4">
            <Field
              label={"Net Value"}
              value={formatCurrency(matterInvoice?.totalNetValue)}
            />
          </Col>
        </Row>

        <div className="lp-color-title primary full-width">Invoice Values</div>
        <Row>
          <Col className="mb-4">
            <Field
              label={"Gross Value"}
              value={formatCurrency(matterInvoice?.invoicedTotalGrossValue)}
            />
          </Col>
          <Col className="mb-4">
            <Field
              label={"VAT Value"}
              value={formatCurrency(matterInvoice?.invoicedTotalVATValue)}
            />
          </Col>
          <Col className="mb-4">
            <Field
              label={"Net Value"}
              value={formatCurrency(matterInvoice?.invoicedTotalNetValue)}
            />
          </Col>
        </Row>

        <div className="lp-color-title primary full-width">Adjustments</div>
        <Row>
          <Col className="mb-4">
            <Field
              label={"Gross Value"}
              value={formatCurrency((matterInvoice?.invoicedTotalGrossValue ?? 0) - (matterInvoice?.totalGrossValue ?? 0))}
            />
          </Col>
          <Col as={Col} className="mb-4">
            <Field
              label={"VAT Value"}
              value={formatCurrency((matterInvoice?.invoicedTotalVATValue ?? 0) - (matterInvoice?.totalVATValue ?? 0))}
            />
          </Col>
          <Col as={Col} className="mb-4">
            <Field
              label={"Net Value"}
              value={formatCurrency((matterInvoice?.invoicedTotalNetValue ?? 0) - (matterInvoice?.totalNetValue ?? 0))}
            />
          </Col>
        </Row>

        {props.showDocumentActions && !matterInvoice?.matter?.isClosed &&
          <div className="lp-slide-panel-sticky-bottom">
            <Row>
              <Col className="d-flex flex-md-row-reverse justify-content-between flex-wrap">
                {matterInvoice?.detailedDocumentId ?
                  <Button onClick={() => onClickViewDocument(matterInvoice?.detailedDocumentId!)} variant="secondary-400">
                    {matterInvoice?.matterInvoiceStatusId != MatterInvoiceStatusesIds.DraftId ? "View detailed document" : "Preview detailed document"}
                  </Button>
                  :
                  <Button onClick={() => onClickCreateDocument(true)} variant="secondary-400">
                    Create detailed document
                  </Button>
                }
                {matterInvoice?.summaryDocumentId ?
                  <Button onClick={() => onClickViewDocument(matterInvoice?.summaryDocumentId!)} variant="secondary-400">
                    {matterInvoice?.matterInvoiceStatusId != MatterInvoiceStatusesIds.DraftId ? "View summary document" : "Preview summary document"}
                  </Button>
                  :
                  <Button onClick={() => onClickCreateDocument(false)} variant="secondary-400">
                    Create summary document
                  </Button>
                }
                {matterInvoice?.hasPayments &&
                  <>
                    {matterInvoice?.latestReceiptDocumentId && matterInvoice?.allowNewReceiptCreation == false ?
                      <Button onClick={() => onClickViewDocument(matterInvoice?.latestReceiptDocumentId!)} variant="secondary-400">
                        View Latest Receipt
                      </Button>
                      :
                      <Button onClick={() => onClickCreateReceipt()} variant="secondary-400">
                        Create Receipt
                      </Button>
                    }
                  </>
                }
              </Col>
            </Row>
          </div>
        }
      </div>
    </>
  );
}

export default ViewMatterInvoice;
