import { Controller, useForm, useFieldArray } from "react-hook-form";
import { useEffect, useRef, useState } from "react";
import { vestResolver } from "@hookform/resolvers/vest";
import { Form, Button, Row, Col, InputGroup } from "react-bootstrap";
import useSlidingPanelActions from "actions/slidingPanel";
import CustomSelect from "components/Select/Select";
import Loader from "components/Loader";
import { getValidationSuite } from "./validationSuite";
import { getInitialsForName, removeEmptyFields } from "utils/form";
import { UpdateParticipatingEntityModel } from "models/update/UpdateParticipatingEntityModel";
import { getParticipatingEntityById, updateParticipatingEntity } from "actions/matter";
import { getBailConditions, getBailStatuses } from "actions/entity";
import { ParticipatingEntityModel } from "models/view/ParticipatingEntityModel";
import useGridActions from "actions/grid";
import { useAppSelector } from "hooks/appSelector";
import DatePicker from "react-datepicker";
import { DateFormat } from "utils/constants";
import { BailStatusIds } from "enums/BailStatusIds";
import Title from 'components/Title/index';
import { MdAdd, MdClose, MdWarning } from 'react-icons/md';
import { MatterCriminalBailConditionModel } from "models/view/MatterCriminalBailConditionModel";
import { MatterTypeIds } from "enums/MatterTypeIds";
import store from "state/store";
import { ModalState } from "state/modalSlice";
import useModalActions from "actions/modal";
import { getDateOnly } from "utils/date";
import { getInvoiceDueDateSettingTypes } from "actions/lte";
import { getEntityRoles } from "actions/settings";
import FormErrorButton from "components/Buttons/FormErrorButton";
import { EntityRoleModel } from "models/view/EntityRoleModel";
import usePageActions from "actions/page";
import { BadgeIds } from "enums/BadgeIds";

type Props = {
  participatingEntityId: string,
  matterTypeId: string,
  onSubmitCallback?: Function,
}

export default function EditParticipatingEntityForm(props: Props) {
  const [genericErrors, setGenericErrors] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [participatingEntity, setParticipatingEntity] = useState<ParticipatingEntityModel>();
  const slidingPanelActions = useSlidingPanelActions();
  const currentSlidingPanelState = useAppSelector((state) => state.slidingPanel);
  const isFirstRender = useRef<boolean>(true);
  //const [roleId, setRoleId] = useState<string>('');
  const [bailStatusId, setBailStatusId] = useState<string>('');
  const gridActions = useGridActions();
  const pageActions = usePageActions();
  const modalActions = useModalActions();
  const grid = useAppSelector((state) => state.grid);
  const [initialIsDefault, setInitialIsDefault] = useState<boolean>(false);

  useEffect(() => {
    if(props?.participatingEntityId) {
      setIsLoading(true);
      getParticipatingEntityById(props?.participatingEntityId ?? '')
        .then((response) => {
          setParticipatingEntity(response.data);
          const adapterCriminalBailConditions : any[] = [];
          if(response.data?.matterCriminalBailConditions !== undefined){
            (response.data?.matterCriminalBailConditions as MatterCriminalBailConditionModel[]).forEach(
              x => (adapterCriminalBailConditions.push({
                id: x.id,
                bailConditionId: x?.bailCondition?.id ?? '',
                additionalText: x?.additionalText ?? ''
              }))
            )
          }

          const initialState: UpdateParticipatingEntityModel = {
            id: response.data?.id,
            matterId: response.data?.matterId,
            entityId: response.data?.entity?.id,
            entityRoleId: response.data?.entityRoleId,
            entityRole: response.data?.entityRole,
            notes: response.data?.notes,
            entityMatterReference: response.data?.entityMatterReference,
            isInvoicingParty: response.data?.isInvoicingParty,
            isDefaultClient: response.data?.isDefaultClient,
            purchaseOrderReference: response.data?.purchaseOrderReference,
            invoiceDueDateSettingNumber: response.data?.invoiceDueDateSettingNumber,
            invoiceDueDateSettingTypeId: response.data?.invoiceDueDateSettingTypeId,
            displayName: response.data?.entity?.displayName,
            bailStatusId: response.data?.bailStatus?.id,
            custodyTimeLimitExpiryDate: response.data?.custodyTimeLimitExpiryDate,
            createMatterCriminalBailConditions: adapterCriminalBailConditions
          }
          setBailStatusId(response.data?.bailStatus?.id);
          setInitialIsDefault(response.data?.isDefaultClient);
          reset(initialState);
        }
      )
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
    }
  }, []);

  const {register, reset, control, setValue, handleSubmit, watch, formState: {errors}} = useForm<UpdateParticipatingEntityModel>({
    resolver: vestResolver(getValidationSuite(props.matterTypeId)),
    defaultValues: {
      createMatterCriminalBailConditions: [{
        id: undefined,
        bailConditionId: "",
        additionalText: ""
      }]
    }
  });

  const isThereAnyDataOrDefaultClient = () => {
    const gridState = store.getState().grid;

    if (!gridState.rowData || gridState?.rowData.length === 0) {
      return false;
    }

    return true;
  };

  const hasOnlyALayClient = () => {
    const gridState = store.getState().grid;

    if (gridState?.rowData.filter(x => x.entityRole?.isLayClient).length == 1) {
      return true;
    }

    return false;
  }

  const hasAInstructingSolicitor = () => {
    const gridState = store.getState().grid;
    return gridState?.rowData.some(x => x.entityRole?.isInstructingSolicitor && x.id != props.participatingEntityId);
  }

  const updateParticipatingEntityCallback = (
    data: UpdateParticipatingEntityModel,
    fromModal: boolean = true
  ) => {
    setIsLoading(true);
    if(fromModal)
    {
      modalActions.toggleModalLoading();
    }
    updateParticipatingEntity(data)
      .then((response) => {
        let newData;
        if(data.isDefaultClient == true && grid.rowData.some(x => x.isDefaultClient == true)) {
          newData = grid.rowData.map((obj) => {
            if (obj.isDefaultClient == true) {
              return { ...obj, isDefaultClient: false };
            } else {
              return obj;
            }
          });
        }
        let index: number = grid.rowData.findIndex((x: any) => x.id === response.data.id);
        let newEntry: ParticipatingEntityModel = {...response.data};
        let newArray: Array<any> = newData ? [...newData] : [...grid.rowData];
        newArray[index] = newEntry;
        gridActions.setGridRowData(newArray);

        pageActions.triggerReloadBadge(BadgeIds.MatterParticipatingEntities);
        slidingPanelActions.clearSlidingPanel();
        reset();

        props.onSubmitCallback && props.onSubmitCallback();
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);
        if(fromModal)
        {
          modalActions.toggleModalLoading();
          modalActions.toggleModalShownStatus();
        }
      });
  };

  const showWarningIfOtherLayClients = (
    data: UpdateParticipatingEntityModel
  ) => {
    var bodyMessage: React.ReactNode = (
      <div className="lp-modal-warning">
        <MdWarning />
        This Matter already has a Lay Client that is set as the Default Client. It will be replaced as the Default Client by this one.
        <br />
        Are you sure you want to do that? If not, uncheck the Default Client
        checkbox.
      </div>
    );
    let modal: ModalState = {
      title: 'Update confirmation',
      body: bodyMessage,
      actionText: 'Update',
      onAction: () => updateParticipatingEntityCallback(data),
      show: false,
    };
    modalActions.setModal(modal);
    modalActions.toggleModalShownStatus();
  };

  const showWarningIfInstructingSolicitorAndThereAreExistingLayClients = (
    data: UpdateParticipatingEntityModel
  ) => {
    var bodyMessage: React.ReactNode = (
      <div className="lp-modal-warning">
        <MdWarning />
        This Matter already has a Lay Client that is set as the Default Client. It will be replaced as the Default Client by this Instructing Solicitor.
        <br />
        Are you sure you want to do that?
      </div>
    );
    let modal: ModalState = {
      title: 'Update confirmation',
      body: bodyMessage,
      actionText: 'Update',
      onAction: () => updateParticipatingEntityCallback(data),
      show: false,
    };
    modalActions.setModal(modal);
    modalActions.toggleModalShownStatus();
  };

  useEffect(() => {
    //this is for the initial useEffect call
    if(watch('entityRole') == undefined) {
      return;
    }

    //this is for the first set action of the roleId
    if(isFirstRender.current) {
      isFirstRender.current = false;
      return;
    } 

    if(watch('entityRole')?.isLayClient || watch('entityRole')?.isInstructingSolicitor)
    {
      setValue('isInvoicingParty', true);
    }

    //if is default client and the role changes from instructing solicitor to lay client or the other way around the flag does not change
    if(watch('isDefaultClient') && 
      (watch('entityRole')?.isLayClient || watch('entityRole')?.isInstructingSolicitor))
    {
      return;
    }

    //if the role is not lay client or instructing solicitor set the default client flag to false
    if(!watch('entityRole')?.isLayClient && !watch('entityRole')?.isInstructingSolicitor)
    {
      setValue('isDefaultClient', false);
    }
    else 
    {
      //we do this set value action only if the roleId is changed by the user, not when the change is triggered by initializations
      setValue(
        'isDefaultClient',
        !hasAInstructingSolicitor() && ((!isThereAnyDataOrDefaultClient() && watch('entityRole')?.isLayClient) ||
          (hasOnlyALayClient() && watch('entityRole')?.isLayClient) || !!watch('entityRole')?.isInstructingSolicitor)
      );
    }
  }, [watch('entityRole')]);

  const onSubmit = handleSubmit((data) => submitData(data));
    
  async function submitData(data: UpdateParticipatingEntityModel) {
    removeEmptyFields(data);
    if (data.isDefaultClient == true && data.entityRole?.isLayClient &&
      !grid.rowData.some(x => x.entityRole?.isInstructingSolicitor) &&
      grid.rowData.some(x => x.isDefaultClient == true && x.id != props.participatingEntityId)
      ) 
    {
      showWarningIfOtherLayClients(data);
      return;
    }
    if (
      data.entityRole?.isInstructingSolicitor &&
      grid.rowData.some(x => x.entityRole?.isLayClient && x.isDefaultClient == true && x.id != props.participatingEntityId) &&
      !grid.rowData.some(x => x.entityRole?.isInstructingSolicitor)
      ) 
    {
      showWarningIfInstructingSolicitorAndThereAreExistingLayClients(data);
      return;
    }
    updateParticipatingEntityCallback(data, false);
  }

  const cancelForm = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    slidingPanelActions.clearSlidingPanel();
    reset();

    currentSlidingPanelState.onCancel && currentSlidingPanelState.onCancel();
  }

  const { fields, append, remove } = useFieldArray({
    name: "createMatterCriminalBailConditions",
    control,
    rules: {
      required: "Please append at least 1 item"
    }
  });

  const appendNewLevel = () => {
    append({
      id: undefined,
      bailConditionId: "",
      additionalText: ""
    }, { shouldFocus: false });
  }

  const onChangeBailCondition = (bailCondition: any, index: number) => {
    setValue(`createMatterCriminalBailConditions.${index}.additionalText`, bailCondition?.additionalText ?? '');
  }

  const resetBailStatus = () => {
    setValue("bailStatusId", '');
  }

  const resetCustodyTimeLimitExpiryDate = () => {
    setValue("custodyTimeLimitExpiryDate", undefined);
  }

  const resetBailConditions = () => {
    setValue("createMatterCriminalBailConditions", [{
      bailConditionId: "",
      additionalText: ""
    }]);
  }

  const onChangeIsInvoicingParty = (value: boolean) => {
    if(!value) {
      setValue("purchaseOrderReference", undefined);
      setValue("invoiceDueDateSettingNumber", undefined);
      setValue("invoiceDueDateSettingTypeId", undefined);
    }
  }

  const onChangeBailStatus = (value: any) => {
    setBailStatusId(value?.id);
    resetBailConditions();
    if(value?.id != BailStatusIds.RemandedInCustodyId) {
      setValue("custodyTimeLimitExpiryDate", undefined);
    }
  };

  const onChangeInvoiceDueDateSettingType = (val: any) => {
    if(!val){
      setValue('invoiceDueDateSettingNumber', undefined);
    }
  };

  const onChangeEntityRole = (val: EntityRoleModel) => {
    setValue('entityRole', val);
    setBailStatusId('');
    resetBailStatus();
    resetBailConditions();
    resetCustodyTimeLimitExpiryDate();
  };

  return (
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Form onSubmit={onSubmit} className="d-flex flex-column h-100">
        <Row>
          <Form.Group as={Col} controlId="entityId">
            <div className="lp-entity-profile">
              <div className="lp-entity-photo">
                {getInitialsForName(participatingEntity?.entity?.displayName)}
              </div>
              <div className="lp-entity-name">
                {participatingEntity?.entity.displayName}
              </div>
            </div>
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} sm={6} controlId="entityRoleId">
            <Form.Label className="required">Role</Form.Label>
            <Controller
              control={control}
              name="entityRoleId"
              shouldUnregister={true}
              render={({field: { onChange, value }}) => (
                <CustomSelect
                  id="entityRoleId"
                  endpointCall={getEntityRoles}
                  value={value}
                  onChange={val => {onChange(val?.id ?? null); onChangeEntityRole(val);}}
                />
              )}
            />
            <Form.Text className="lp-error">
              {errors?.entityRoleId?.message && (errors.entityRoleId.message)}
            </Form.Text>
          </Form.Group>
          
          <Form.Group as={Col} sm={6} className="mt-4 mt-sm-0" controlId="entityMatterReference">
            <Form.Label>Matter Reference</Form.Label>
            <Form.Control
              type="text"
              className={`${errors?.notes?.message ? 'invalid' : ''}`}
              {...register("entityMatterReference", {shouldUnregister: true})}
            />
            <Form.Text className="lp-error">
              {errors?.entityMatterReference?.message && (errors.entityMatterReference.message)}
            </Form.Text>
          </Form.Group>
        </Row>

        <Row>
          {(watch("entityRole")?.isLayClient || watch("entityRole")?.isInstructingSolicitor) &&
            <Form.Group as={Col} sm={6} controlId="isDefaultClient">
              <Form.Label>Is Default Client</Form.Label>
              <Controller
                control={control}
                name="isDefaultClient"
                shouldUnregister={true}
                render={({ field: { onChange, value, name, ref } }) => (
                  <Form.Check type="switch" id="isDefaultClient">
                    <Form.Check.Input
                      className="form-check-input"
                      disabled={
                        (!isThereAnyDataOrDefaultClient() && watch("entityRole")?.isLayClient) ||
                        (hasOnlyALayClient() && watch("entityRole")?.isLayClient && initialIsDefault) ||
                        hasAInstructingSolicitor() || watch("entityRole")?.isInstructingSolicitor
                      }
                      ref={ref}
                      checked={value}
                      onChange={(ev: any) => onChange(ev.target.checked)}
                    />
                  </Form.Check>
                )}
              />
            </Form.Group>
          }

          <Form.Group as={Col} sm={6} className={(watch("entityRole")?.isLayClient || watch("entityRole")?.isInstructingSolicitor) ? 'mt-4 mt-sm-0' : ''} controlId="isInvoicingParty">
            <Form.Label>Is Invoicing Party</Form.Label>
            <Controller
              control={control}
              name="isInvoicingParty"
              shouldUnregister={true}
              render={({field: { onChange, value, name, ref }}) => (
                <Form.Check 
                  type="switch"
                  id="isInvoicingParty">
                    <Form.Check.Input
                      className= "form-check-input"
                      ref={ref}
                      checked={value}
                      onChange={(ev: any) => {onChange(ev.target.checked); onChangeIsInvoicingParty(ev.target.checked);}}
                    />
                </Form.Check>
              )}
            />
          </Form.Group>
        </Row>

        {watch("isInvoicingParty") &&
          <Row>
            <Form.Group as={Col} sm={6} controlId="purchaseOrderReference">
              <Form.Label>Purchase Order Reference</Form.Label>
              <Form.Control
                type="text"
                className={`${errors?.purchaseOrderReference?.message ? 'invalid' : ''}`}
                {...register("purchaseOrderReference", {shouldUnregister: true})}
              />
              <Form.Text className="lp-error">
                {errors?.purchaseOrderReference?.message && (errors.purchaseOrderReference.message)}
              </Form.Text>
            </Form.Group>

            <Form.Group as={Col} sm={6} className="mt-4 mt-sm-0">
              <Form.Label htmlFor="invoiceDueDateSettingNumber">Invoice Due Date</Form.Label>
              <InputGroup className="lp-invoice-due-date">
                <Form.Control
                  type="number"
                  className={`${errors?.invoiceDueDateSettingNumber?.message ? 'invalid' : ''}`}
                  {...register("invoiceDueDateSettingNumber", {shouldUnregister: true})}
                  min="0"
                  step="1"
                  onWheel={e => e.currentTarget.blur()}
                />
                <Controller
                  control={control}
                  name={`invoiceDueDateSettingTypeId`}
                  shouldUnregister={true}
                  render={({ field: { onChange, value, name, ref } }) => (
                    <CustomSelect
                      id="invoiceDueDateSettingTypeId"
                      inputRef={ref}
                      className={`lp-select${errors?.invoiceDueDateSettingTypeId?.message ? ' invalid' : ''}`}
                      endpointCall={getInvoiceDueDateSettingTypes}
                      value={value}
                      onChange={val => { onChange(val?.id ?? null); onChangeInvoiceDueDateSettingType(val?.id); }}
                      isClearable
                    />
                  )}
                />
              </InputGroup>
              <Form.Text className="lp-error">
                {errors?.invoiceDueDateSettingNumber?.message && (errors.invoiceDueDateSettingNumber.message)}
                {errors?.invoiceDueDateSettingTypeId?.message && (errors.invoiceDueDateSettingTypeId.message)}
              </Form.Text>
            </Form.Group>
          </Row>
        }

        <Row>
          <Form.Group as={Col} controlId="notes">
            <Form.Label>Notes</Form.Label>
            <Form.Control as="textarea" rows={10}
              className={`${errors?.notes?.message ? 'invalid' : ''}`}
              {...register("notes", {shouldUnregister: true})}
            />
            <Form.Text className="lp-error">
              {errors?.notes?.message && (errors.notes.message)}
            </Form.Text>
          </Form.Group>
        </Row>

        {watch("entityRole")?.isLayClient && props.matterTypeId === MatterTypeIds.CriminalLawActingForAccusedId && 
          <>
            <hr/>

            <Row className="mb-4">
              <Form.Group as={Col} sm={bailStatusId === BailStatusIds.RemandedInCustodyId ? 6 : 12} controlId="bailStatusId">
                <Form.Label className="required">Bail Status</Form.Label>
                <Controller
                  control={control}
                  name="bailStatusId"
                  shouldUnregister={true}
                  render={({field: { onChange, value }}) => (
                    <CustomSelect
                      id="bailStatusId"
                      endpointCall={getBailStatuses}
                      value={value}
                      onChange={val => {onChange(val?.id ?? null); onChangeBailStatus(val);}}
                      menuPlacement="top"
                    />
                  )}
                />
                <Form.Text className="lp-error">
                  {errors?.bailStatusId?.message && (errors.bailStatusId.message)}
                </Form.Text>
              </Form.Group>

              {bailStatusId === BailStatusIds.RemandedInCustodyId &&
                <Form.Group as={Col} sm={6} className="mt-4 mt-sm-0" controlId="custodyTimeLimitExpiryDate">
                  <Form.Label className={bailStatusId === BailStatusIds.RemandedInCustodyId ? "required" : ""}>
                    Custody Time Limit Expiry Date
                  </Form.Label>
                  <Controller
                    control={control}
                    name="custodyTimeLimitExpiryDate"
                    shouldUnregister={true}
                    render={({field: { onChange, value }}) => (
                      <DatePicker
                        className={`${errors?.custodyTimeLimitExpiryDate?.message ? 'invalid' : ''}`}
                        id="custodyTimeLimitExpiryDate"
                        dateFormat={DateFormat.Datepicker}
                        selected={value ? getDateOnly(value) : null}
                        onChange={(val) => onChange(val != null ? getDateOnly(val) : val)}
                        showMonthDropdown
                        showYearDropdown
                        autoComplete="off"
                      />
                    )}
                  />
                  <Form.Text className="lp-error">
                    {errors?.custodyTimeLimitExpiryDate?.message && (errors.custodyTimeLimitExpiryDate.message)}
                  </Form.Text>
                </Form.Group>
              }
            </Row>

            {(bailStatusId === BailStatusIds.RemandedOnConditionalBailId || bailStatusId === BailStatusIds.ReleasedOnConditionalPoliceBailId) &&
              <div className="lp-bail-conditions">
                <Title type="section" title={"Bail Conditions"}>
                  <Button onClick={appendNewLevel} className="btn-icon" variant="success">
                    <MdAdd />
                  </Button>
                </Title>
                {fields.map((field, index) => {
                  return (
                    <section key={field.id} className="lp-bail-condition-item mb-4">
                      <Button
                        variant="danger"
                        onClick={() => remove(index)}
                        className={`delete-item btn-icon${fields.length === 1 ? ' disabled' : ''}`}
                      >
                        <MdClose />
                      </Button>

                      <Form.Group className="mb-4" controlId={`createMatterCriminalBailConditions.${index}.bailConditionId`}>
                        <Form.Label className="required">Bail Condition</Form.Label>
                        <Controller
                          control={control}
                          name={`createMatterCriminalBailConditions.${index}.bailConditionId`}
                          shouldUnregister={true}
                          render={({ field: { onChange, value, name, ref } }) => (
                            <CustomSelect
                              id={`createMatterCriminalBailConditions.${index}.bailConditionId`}
                              inputRef={ref}
                              className={`lp-select${errors?.createMatterCriminalBailConditions?.[index]?.bailConditionId?.message ? ' invalid' : ''}`}
                              endpointCall={getBailConditions}
                              value={value}
                              onChange={val => {onChange(val?.id ?? null); onChangeBailCondition(val, index)}}
                              menuPlacement="top"
                            />
                          )}
                        />
                        <Form.Text className="lp-error">
                          {errors?.createMatterCriminalBailConditions?.[index]?.bailConditionId?.message && (errors?.createMatterCriminalBailConditions?.[index]?.bailConditionId?.message)}
                        </Form.Text>
                      </Form.Group>

                      <Form.Group controlId={`createMatterCriminalBailConditions.${index}.additionalText`}>
                        <Form.Label className="required">Additional Text</Form.Label>
                        <Form.Control as="textarea" rows={5}
                          className={`${errors?.createMatterCriminalBailConditions?.[index]?.additionalText?.message ? 'invalid' : ''}`}
                          {...register(`createMatterCriminalBailConditions.${index}.additionalText`, {shouldUnregister: true})}
                        />
                        <Form.Text className="lp-error">
                          {errors?.createMatterCriminalBailConditions?.[index]?.additionalText?.message && (errors?.createMatterCriminalBailConditions?.[index]?.additionalText?.message)}
                        </Form.Text>
                      </Form.Group>
                    </section>
                  );
                })}
              </div>
            }
          </>
        }

        <div className="lp-slide-panel-sticky-bottom">
          <Form.Group className="d-flex justify-content-between">
            { Object.keys(errors).length
              ? <FormErrorButton text="Update" />
              : <Button variant="success" type="submit">Update</Button>
            }
            <Button variant="secondary-400" onClick={cancelForm}>Cancel</Button>
          </Form.Group>
        </div>
      </Form>
    </>
  );
}

