import ButtonGrid from "components/Buttons/ButtonGrid";
import { MouseEventHandler } from "react";
import { MdDelete, MdEdit, MdRemoveRedEye, MdOutlineCheck, 
  MdPhone, MdEmail, MdOutlineLinkOff, MdSell, MdAccountCircle,
  MdOutlineContentCopy, MdOutlineLink, MdOutlinePayment, MdMoneyOff, MdDownload } from "react-icons/md";
import { TbDots, TbEyeEdit, TbReportAnalytics, TbReportOff } from "react-icons/tb";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import { LuFlag, LuFlagOff } from "react-icons/lu";
import { FaArrowRight, FaChevronDown, FaChevronUp, FaFileExport, FaMailBulk, FaPrint, FaShare } from "react-icons/fa";
import { GrTransaction } from "react-icons/gr";

const IconButtonGrid = (
  buttonData: {value?: string, type?: ActionButtonTypes, callback?: MouseEventHandler<HTMLElement>, id?: string}
) => {
  const buttonId = buttonData.id ?? "button";
  switch(buttonData.type) {
    case ActionButtonTypes.View: {
      return <ButtonGrid
        key={buttonId + "0"}
        onClick={buttonData.callback}
        className={"secondary-400"}
        icon={<MdRemoveRedEye />}
      />;
    }
    case ActionButtonTypes.Edit: {
      return  <ButtonGrid
        key={buttonId + "1"}
        onClick={buttonData.callback}
        className={"secondary-400"}
        icon={<MdEdit />}
      />;
    }
    case ActionButtonTypes.EditOnlyIsPreferred: {
      return  <ButtonGrid
        key={buttonId + "2"}
        onClick={buttonData.callback}
        className={"secondary-400"}
        icon={<MdEdit />}
      />;
    }
    case ActionButtonTypes.Delete: {
      return <ButtonGrid
        key={buttonId + "3"}
        onClick={buttonData.callback}
        className={"danger"}
        icon={<MdDelete />}
      />;
    }
    case ActionButtonTypes.Unlink: {
      return <ButtonGrid
        key={buttonId + "4"}
        onClick={buttonData.callback}
        className={"danger"}
        icon={<MdOutlineLinkOff />}
      />;
    }
    case ActionButtonTypes.Deactivate: {
      return <ButtonGrid
        key={buttonId + "5"}
        onClick={buttonData.callback}
        className={"success"}
        icon={<MdOutlineCheck />}
      />;
    }
    case ActionButtonTypes.Activate: {
      return <ButtonGrid
        key={buttonId + "6"}
        onClick={buttonData.callback}
        className={"secondary-400"}
        icon={<MdOutlineCheck />}
      />;
    }
    case ActionButtonTypes.ShowMore: {
      return <ButtonGrid
        key={buttonId + "7"}
        onClick={buttonData.callback}
        className={'secondary-400'}
        icon={<TbDots />}
      />;
    }
    case ActionButtonTypes.Call: {
      return <a key={buttonId + "8"} href={'tel:' + buttonData?.value ?? ''}>
        <ButtonGrid
          className={'secondary-400'}
          icon={<MdPhone />}
        />
      </a>;
    }
    case ActionButtonTypes.Email: {
      return <a key={buttonId + "9"} href={'mailto:' + buttonData?.value ?? ''}>
        <ButtonGrid
          className={'secondary-400'}
          icon={<MdEmail />}
        />
      </a>;
    }
    case ActionButtonTypes.EditTaxonomyTerms: {
      return <ButtonGrid
        key={buttonId + "10"}
        onClick={buttonData.callback}
        className={"secondary-400"}
        icon={<MdSell />}
      />;
    }
    case ActionButtonTypes.OpenFile: {
      return <ButtonGrid
        key={buttonId + "11"}
        onClick={buttonData.callback}
        className={"secondary-400"}
        icon={<MdRemoveRedEye />}
      />;
    }
    case ActionButtonTypes.PreviewFile: {
      return <ButtonGrid
        key={buttonId + "12"}
        onClick={buttonData.callback}
        className={"secondary-400"}
        icon={<MdRemoveRedEye />}
      />;
    }
    case ActionButtonTypes.Copy: {
      return <ButtonGrid
        key={buttonId + "13"}
        onClick={buttonData.callback}
        className={"secondary-400"}
        icon={<MdOutlineContentCopy />}
      />;
    }
    case ActionButtonTypes.PreviewEmail: {
      return <ButtonGrid
        key={buttonId + "14"}
        onClick={buttonData.callback}
        className={"secondary-400"}
        icon={<MdRemoveRedEye />}
      />;
    }
    case ActionButtonTypes.LinkToRecordableEvent: {
      return <ButtonGrid
        key={buttonId + "15"}
        onClick={buttonData.callback}
        className={"secondary-400"}
        icon={<MdOutlineLink />}
      />;
    }
    case ActionButtonTypes.UnlinkIncidentalExpenses: {
      return <ButtonGrid
        key={buttonId + "16"}
        onClick={buttonData.callback}
        className={"secondary-400"}
        icon={<MdOutlineLinkOff />}
      />;
    }
    case ActionButtonTypes.Payment: {
      return <ButtonGrid
        key={buttonId + "17"}
        onClick={buttonData.callback}
        className={"secondary-400"}
        icon={<MdOutlinePayment />}
      />;
    }
    case ActionButtonTypes.MarkAsDraft: {
      return <ButtonGrid
        key={buttonId + "18"}
        onClick={buttonData.callback}
        className={"secondary-400"}
        icon={<MdOutlineCheck />}
      />;
    }
    case ActionButtonTypes.ShowOnMatterStatisticsCards: {
      return <ButtonGrid
        key={buttonId + "19"}
        onClick={buttonData.callback}
        className={"secondary-400"}
        icon={<TbReportAnalytics />}
      />;
    }
    case ActionButtonTypes.HideFromFromMatterStatisticsCards: {
      return <ButtonGrid
        key={buttonId + "20"}
        onClick={buttonData.callback}
        className={"secondary-400"}
        icon={<TbReportOff />}
      />;
    }
    case ActionButtonTypes.ViewParentResource: {
      return <ButtonGrid
        key={buttonId + "21"}
        onClick={buttonData.callback}
        className={"secondary-400"}
        icon={<TbEyeEdit />}
      />;
    }
    case ActionButtonTypes.WriteOff: {
      return <ButtonGrid
        key={buttonId + "22"}
        onClick={buttonData.callback}
        className={"secondary-400"}
        icon={<MdMoneyOff />}
      />;
    }
    case ActionButtonTypes.Download: {
      return <ButtonGrid
        key={buttonId + "23"}
        onClick={buttonData.callback}
        className={"secondary-400"}
        icon={<MdDownload />}
      />;
    }
    case ActionButtonTypes.Abate: {
      return <ButtonGrid
        key={buttonId + "24"}
        onClick={buttonData.callback}
        className={"secondary-400"}
        icon={<MdMoneyOff />}
      />;
    }
    case ActionButtonTypes.ViewProfile: {
      return <ButtonGrid
        key={buttonId + "25"}
        onClick={buttonData.callback}
        className={"secondary-400"}
        icon={<MdAccountCircle />}
      />;
    }
    case ActionButtonTypes.Flag: {
      return <ButtonGrid
        key={buttonId + "26"}
        onClick={buttonData.callback}
        className={"secondary-400"}
        icon={<LuFlag />}
      />;
    }
    case ActionButtonTypes.Unflag: {
      return <ButtonGrid
        key={buttonId + "27"}
        onClick={buttonData.callback}
        className={"secondary-400"}
        icon={<LuFlagOff />}
      />;
    }
    case ActionButtonTypes.IncreasePriority: {
      return <ButtonGrid
        key={buttonId + "28"}
        onClick={buttonData.callback}
        className={"secondary-400"}
        icon={<FaChevronUp />}
      />;
    }
    case ActionButtonTypes.DecreasePriority: {
      return <ButtonGrid
        key={buttonId + "29"}
        onClick={buttonData.callback}
        className={"secondary-400"}
        icon={<FaChevronDown />}
      />;
    }
    case ActionButtonTypes.Print: {
      return <ButtonGrid
        key={buttonId + "30"}
        onClick={buttonData.callback}
        className={"secondary-400"}
        icon={<FaPrint />}
      />;
    }
    case ActionButtonTypes.Move: {
      return <ButtonGrid
        key={buttonId + "31"}
        onClick={buttonData.callback}
        className={"secondary-400"}
        icon={<FaArrowRight />}
      />;
    }
    case ActionButtonTypes.Share: {
      return <ButtonGrid
        key={buttonId + "32"}
        onClick={buttonData.callback}
        className={"secondary-400"}
        icon={<FaShare />}
      />;
    }
    case ActionButtonTypes.SendFiles: {
      return <ButtonGrid
        key={buttonId + "33"}
        onClick={buttonData.callback}
        className={"secondary-400"}
        icon={<FaFileExport />}
      />;
    }
    case ActionButtonTypes.ConvertToMail: {
      return <ButtonGrid
        key={buttonId + "34"}
        onClick={buttonData.callback}
        className={"secondary-400"}
        icon={<FaMailBulk />}
      />;
    }
    case ActionButtonTypes.ViewTransaction: {
      return <ButtonGrid
        key={buttonId + "35"}
        onClick={buttonData.callback}
        className={"secondary-400"}
        icon={<GrTransaction />}
      />;
    }
    default:
      return <></>;
  }
}

export default IconButtonGrid;
