import { Controller, useForm } from "react-hook-form";
import { useState } from "react";
import { createMatter, getConflicts, getFundingAssuranceChecks, getMatterTypes, getRiskRatings } from "actions/matter";
import { CreateMatterModel } from "models/create/CreateMatterModel";
import { vestResolver } from "@hookform/resolvers/vest";
import Loader from "components/Loader";
import { Form, Button, Row, Col } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { getChargingSchemes } from "actions/chargingSchemes";
import useSlidingPanelActions from "actions/slidingPanel";
import { MatterTypeIds } from "enums/MatterTypeIds";
import { removeEmptyFields } from "utils/form";
import CustomSelect from "components/Select/Select";
import { validationSuite } from "./validationSuite";
import FormErrorButton from "components/Buttons/FormErrorButton";
import { Priority } from "enums/Priority";
import { getPriorities } from "utils/misc";

function CreateMatterForm() {
  const [isLoading, setIsLoading] = useState(false);
  const [genericErrors, setGenericErrors] = useState(null);
  const navigate = useNavigate();

  const slidingPanelActions = useSlidingPanelActions();

  const {register, handleSubmit, control, reset, watch, formState: {errors}} = useForm<CreateMatterModel>({
    resolver: vestResolver(validationSuite),
    defaultValues: {
      priority: Priority.Low,
    }
  });

  const onSubmit = handleSubmit((data) => submitData(data));

  async function submitData(data: CreateMatterModel) {
    setIsLoading(true);

    removeEmptyFields(data);

    createMatter(data).then((response) => {
      navigate(`/matter/${response.data.id}`);

      slidingPanelActions.clearSlidingPanel();
      reset();
    }).catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }

  const cancelForm = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    slidingPanelActions.clearSlidingPanel();
    reset();
  }
 
  return (
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}
      
      <Form onSubmit={onSubmit} className="d-flex flex-column h-100">
        <Row>
          <Form.Group as={Col} sm={6} controlId="displayName">
            <Form.Label className="required">Display Name</Form.Label>
            <Form.Control
              type="text"
              className={`${errors?.displayName?.message ? 'invalid' : ''}`}
              {...register("displayName", {shouldUnregister: true})}
            />
            <Form.Text className="lp-error">
              {errors?.displayName?.message && (errors.displayName.message)}
            </Form.Text>
          </Form.Group>

          <Form.Group as={Col} sm={6} className="mt-4 mt-sm-0" controlId="matterTypeId">
            <Form.Label className="required">Type</Form.Label>
            <Controller
              control={control}
              name="matterTypeId"
              shouldUnregister={true}
              render={({field: { onChange, value, name, ref }}) => (
                <CustomSelect
                  id="matterTypeId"
                  inputRef={ref}
                  className={`lp-select${errors?.matterTypeId?.message ? ' invalid' : ''}`}
                  endpointCall={getMatterTypes}
                  value={value}
                  onChange={val => onChange(val?.id ?? null)}
                />
              )}
            />
            <Form.Text className="lp-error">
              {errors?.matterTypeId?.message && (errors.matterTypeId.message)}
            </Form.Text>
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} controlId="description">
            <Form.Label className="required">Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={5}
              className={`${errors?.description?.message ? 'invalid' : ''}`}
              {...register("description", {shouldUnregister: true})}
            />
            <Form.Text className="lp-error">
              {errors?.description?.message && (errors.description.message)}
            </Form.Text>
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} sm={6} controlId="conflictStatusId">
            <Form.Label className="required">Conflict</Form.Label>
            <Controller
              control={control}
              name="conflictStatusId"
              shouldUnregister={true}
              render={({field: { onChange, value, name, ref }}) => (
                <CustomSelect
                  id="conflictStatusId"
                  inputRef={ref}
                  className={`lp-select${errors?.conflictStatusId?.message ? ' invalid' : ''}`}
                  endpointCall={getConflicts}
                  value={value}
                  onChange={val => onChange(val?.id ?? null)}
                />
              )}
            />
            <Form.Text className="lp-error">
              {errors?.conflictStatusId?.message && (errors.conflictStatusId.message)}
            </Form.Text>
          </Form.Group>

          <Form.Group as={Col} sm={6} className="mt-4 mt-sm-0" controlId="fundingAssuranceStatusId">
            <Form.Label className="required">Funding Assurance</Form.Label>
            <Controller
              control={control}
              name="fundingAssuranceStatusId"
              shouldUnregister={true}
              render={({field: { onChange, value, name, ref }}) => (
                <CustomSelect
                  id="fundingAssuranceStatusId"
                  inputRef={ref}
                  className={`lp-select${errors?.fundingAssuranceStatusId?.message ? ' invalid' : ''}`}
                  endpointCall={getFundingAssuranceChecks}
                  value={value}
                  onChange={val => onChange(val?.id ?? null)}
                />
              )}
            />
            <Form.Text className="lp-error">
              {errors?.fundingAssuranceStatusId?.message && (errors.fundingAssuranceStatusId.message)}
            </Form.Text>
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} sm={6} controlId="riskRatingId">
            <Form.Label className="required">Risk Rating</Form.Label>
            <Controller
              control={control}
              name="riskRatingId"
              shouldUnregister={true}
              render={({field: { onChange, value, name, ref }}) => (
                <CustomSelect
                  id="riskRatingId"
                  inputRef={ref}
                  className={`lp-select${errors?.riskRatingId?.message ? ' invalid' : ''}`}
                  endpointCall={getRiskRatings}
                  value={value}
                  onChange={val => onChange(val?.id ?? null)}
                />
              )}
            />
            <Form.Text className="lp-error">
              {errors?.riskRatingId?.message && (errors.riskRatingId.message)}
            </Form.Text>
          </Form.Group>

          <Form.Group as={Col} sm={6} className="mt-4 mt-sm-0" controlId="chargingSchemeId">
            <Form.Label className="required">Charging Scheme</Form.Label>
            <Controller
              control={control}
              name="chargingSchemeId"
              shouldUnregister={true}
              render={({field: { onChange, value, name, ref }}) => (
                <CustomSelect
                  id="chargingSchemeId"
                  inputRef={ref}
                  className={`lp-select${errors?.chargingSchemeId?.message ? ' invalid' : ''}`}
                  endpointCall={getChargingSchemes}
                  value={value}
                  onChange={val => onChange(val?.id ?? null)}
                />
              )}
            />
            <Form.Text className="lp-error">
              {errors?.chargingSchemeId?.message && (errors.chargingSchemeId.message)}
            </Form.Text>
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} sm={12} controlId="priority">
            <Form.Label className="required">Priority</Form.Label>
            <Controller
              control={control}
              name="priority"
              shouldUnregister={true}
              render={({field: { onChange, value, name, ref }}) => (
                <CustomSelect
                  id="priority"
                  inputRef={ref}
                  className={`lp-select${errors?.priority?.message ? ' invalid' : ''}`}
                  options={getPriorities()}
                  value={value}
                  onChange={val => onChange(val?.id ?? null)}
                />
              )}
            />
            <Form.Text className="lp-error">
              {errors?.priority?.message && (errors.priority.message)}
            </Form.Text>
          </Form.Group>
        </Row>

        {watch("matterTypeId") === MatterTypeIds.CriminalLawActingForAccusedId &&
          <>
            <Row>
              <Form.Group as={Col} sm={6} controlId="urn">
                <Form.Label>URN</Form.Label>
                <Form.Control
                  type="text"
                  className={`${errors?.matterCriminalDefenceDetail?.urn?.message ? 'invalid' : ''}`}
                  {...register("matterCriminalDefenceDetail.urn", {shouldUnregister: true})}
                />
                <Form.Text className="lp-error">
                  {errors?.matterCriminalDefenceDetail?.urn?.message && (errors.matterCriminalDefenceDetail.urn.message)}
                </Form.Text>
              </Form.Group>

              <Form.Group as={Col} sm={6} className="mt-4 mt-sm-0" controlId="custodyRecordNumber">
                <Form.Label>Custody Record Number</Form.Label>
                <Form.Control
                  type="text"
                  className={`${errors?.matterCriminalDefenceDetail?.custodyRecordNumber?.message ? 'invalid' : ''}`}
                  {...register("matterCriminalDefenceDetail.custodyRecordNumber", {shouldUnregister: true})}
                />
                <Form.Text className="lp-error">
                  {errors?.matterCriminalDefenceDetail?.custodyRecordNumber?.message && (errors.matterCriminalDefenceDetail.custodyRecordNumber.message)}
                </Form.Text>
              </Form.Group>
            </Row>

            <Row>
              <Form.Group as={Col} controlId="dsccLogNumber">
                <Form.Label>DSCC Log Number</Form.Label>
                <Form.Control
                  type="text"
                  className={`${errors?.matterCriminalDefenceDetail?.dsccLogNumber?.message ? 'invalid' : ''}`}
                  {...register("matterCriminalDefenceDetail.dsccLogNumber", {shouldUnregister: true})}
                />
                <Form.Text className="lp-error">
                  {errors?.matterCriminalDefenceDetail?.dsccLogNumber?.message && (errors.matterCriminalDefenceDetail.dsccLogNumber.message)}
                </Form.Text>
              </Form.Group>
            </Row>
          </>
        }

        <div className="lp-slide-panel-sticky-bottom">
          <Form.Group className="d-flex justify-content-between">
            { Object.keys(errors).length
              ? <FormErrorButton text="Create" />
              : <Button variant="success" type="submit">Create</Button>
            }
            <Button variant="secondary-400" onClick={cancelForm}>Cancel</Button>
          </Form.Group>
        </div>
      </Form>
    </>
  );
}

export default CreateMatterForm;
