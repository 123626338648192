import { vestResolver } from "@hookform/resolvers/vest";
import { getChargingSchemesFromLte } from "actions/chargingSchemes";
import { 
  getActivitiesForChargingSchemeFeeEarner,
  getActivitiesForChargingSchemeFeeEarnerAndEventType,
  getFeeEarnerFromMatterFeeEarnerOrDefault,
  getMatterClientsSummary,
  getMatterRecordableEventWithItems,
  updateMatterRecordableEvent
} from "actions/matter";
import useSlidingPanelActions from "actions/slidingPanel";
import { getUserSummaryForLte } from "actions/user";
import Field from "components/Fields/Field";
import Loader from "components/Loader/index";
import CustomSelect from "components/Select/Select";
import { ChargingBasisIds } from "enums/ChargingBasisIds";
import { useAppSelector } from "hooks/appSelector";
import { CreateOrUpdateMatterRecordableEventModel } from "models/create/CreateOrUpdateMatterRecordableEventModel";
import { CreateOrUpdateMatterRecordableItemModel } from "models/create/CreateOrUpdateMatterRecordableItemModel";
import { ActivityDropdownForRecordTimeModel } from "models/view/ActivityDropdownForRecordTimeModel";
import { DropDownOptionModel } from "models/view/DropDownOptionModel";
import { MatterRecordableItemModel } from "models/view/MatterRecordableItemModel";
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { MdAdd, MdClose } from 'react-icons/md';
import { DateFormat, RecordableEventMinutesWarningLimit } from "utils/constants";
import { removeEmptyFields } from "utils/form";
import { getValidationSuite } from "./validationSuite";
import MatterFileSelect from "components/Select/MatterFileSelect";
import { MatterIncidentalExpenseModel } from "models/view/MatterIncidentalExpenseModel";
import { MatterFileModel } from "models/view/MatterFileModel";
import { getDateOnly } from "utils/date";
import { MatterRecordableEventModel } from "models/view/MatterRecordableEventModel";
import moment from "moment";
import TooltipIcon from "components/TooltipIcon";
import usePageActions from "actions/page";
import FormErrorButton from "components/Buttons/FormErrorButton";
import { BadgeIds } from "enums/BadgeIds";

type Props = {
  recordableEventId: string,
  matterId: string,
  onSubmitCallback?: Function
}

export default function EditMatterRecordableEvent(props: Props) {
  const [isLoading, setIsLoading] = useState(true);
  const [genericErrors, setGenericErrors] = useState<string | null>(null);
  const genericErrorsRef = useRef<HTMLDivElement>(null);
  const [feeEarnerLevel, setFeeEarnerLevel] = useState<DropDownOptionModel>();
  const [activities, setActivities] = useState<ActivityDropdownForRecordTimeModel[]>([]);
  const [isLoadingActivities, setIsLoadingActivities] = useState<boolean>(false);
  const [hasBilledItems, setHasBilledItems] = useState<boolean>(false);
  const [initialRecordableEvent, setInitialRecordableEvent] = useState<MatterRecordableEventModel | undefined>(undefined);
  const currentSlidingPanelState = useAppSelector((state) => state.slidingPanel);
  const pageActions = usePageActions();
  const [clients, setClients] = useState<DropDownOptionModel[]>([]);
  const [isLoadingClients, setIsLoadingClients] = useState(false);

  const user = useAppSelector((state) => state.user);
  
  const slidingPanelActions = useSlidingPanelActions();

  const { register, handleSubmit, control, setValue, watch, trigger, reset, formState: { errors } } = useForm<CreateOrUpdateMatterRecordableEventModel>({
    resolver: vestResolver(getValidationSuite(clients.length))
  });

  useEffect(() => {
    setIsLoading(true);
    getMatterRecordableEventWithItems(props.matterId, props.recordableEventId).then((response) => {
      setInitialRecordableEvent(response.data);
      const {matterRecordableItems, matterIncidentalExpenses, ...data} = response.data;
      const hasIncidentalExpenses = !!matterIncidentalExpenses?.length;
      //in case startTime and endTime are defined, we need to re-calculate numberOfUnits and numberOfMinutes
      const recordableItemsWithProperData: MatterRecordableItemModel[] = matterRecordableItems.map((x: MatterRecordableItemModel) => ({
        ...x,
        ...(x.startTime && x.endTime ?
          {
            startTime: `${x.startTime.split(":")[0]}:${x.startTime.split(":")[1]}`,
            endTime: `${x.endTime.split(":")[0]}:${x.endTime.split(":")[1]}`,
            numberOfUnits: undefined,
            numberOfMinutes: undefined
          } : {}
        )
      }));
      reset({
        ...data,
        date: getDateOnly(response.data.date),
        eventTypeId: response.data.recordableEventTypeId,
        feeEarnerLevelId: undefined,
        hasIncidentalExpenses: hasIncidentalExpenses,
        incidentalExpenses: []
      });

      let hasBilledIncidentalExpenses = false;
      let hasBilledRecordableItems = false;
      if(hasIncidentalExpenses) {
        matterIncidentalExpenses.map((x: MatterIncidentalExpenseModel, index: number) => {
          appendIncidentalExpense({
            ...x,
            date: getDateOnly(x.date),
            fileIds: x.files?.map((x: MatterFileModel) => x.id),
            isBilled: x.isBilled
          }, { shouldFocus: false });
        });

        hasBilledIncidentalExpenses = matterIncidentalExpenses.some((x: MatterIncidentalExpenseModel) => x.isBilled);
      }

      //get all the data that would be requested onChange if we would select manually everything
      getFeeEarnerFromMatterFeeEarnerOrDefault(props.matterId, response.data.userId!).then((response2) => {
        setFeeEarnerLevel(response2.data);
        setValue('feeEarnerLevelId', response2.data?.id);
        setIsLoadingActivities(true);
        getActivitiesForChargingSchemeFeeEarner(data.chargingSchemeId, response2.data.id!).then((response3) => {
          setActivities(response3.data);
          recordableItemsWithProperData.map((x: MatterRecordableItemModel, index: number) => {
            const activity = response3.data.find((y: any) => y.id == x.activityId);
            //number of units is undefined so it will be re-calculated
            appendRecordableItem({
              id: x.id,
              activityId: x.activityId && activity == undefined ? "" : x.activityId,
              startTime: x.startTime,
              endTime: x.endTime,
              numberOfMinutes: x.numberOfMinutes,
              numberOfUnits: x.numberOfUnits,
              chargingBasis: activity?.chargingBasis,
              minutesPerUnit: Number(activity?.minutesPerUnit),
              isActivityInvalid: x.activityId && activity == undefined ? true : undefined,
              isBilled: x.isBilled
            }, { shouldFocus: false });
            hasBilledRecordableItems = recordableItemsWithProperData.some(x => x.isBilled);

            setHasBilledItems(hasBilledIncidentalExpenses || hasBilledRecordableItems);

            if(x.startTime && x.endTime) {
              onBlurTime(index);
            } else {
              if (watch(`recordableItems.${index}.chargingBasis`) == ChargingBasisIds.TimeId) {
                onBlurNumberOfMinutes(index);
              } else {
                onBlurNumberOfUnits(index);
              }
            }
          });
        })
        .catch((error) => {
          setGenericErrors(error?.response?.data.Message ?? error.message);
        })
        .finally(() => {
          setIsLoading(false);
          setIsLoadingActivities(false);
        });
      })
      .catch((error) => {
        setIsLoading(false);
        setGenericErrors(error?.response?.data.Message ?? error.message);
      });
    })
    .catch((error) => {
      setIsLoading(false);
      setGenericErrors(error?.response?.data.Message ?? error.message);
    });

    setIsLoadingClients(true);
    getMatterClientsSummary(props.matterId).then((response) => {
      setClients(response.data)
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoadingClients(false);
    });
  }, []);

  const { fields: recordableItemFields, append: appendRecordableItem, remove: removeRecordableItem } = useFieldArray({
    name: "recordableItems",
    control,
    rules: {
      required: "Please append at least 1 item"
    }
  });

  const appendNewRecordableItem = () => {
    appendRecordableItem({
      activityId: "",
      startTime: "",
      endTime: "",
      numberOfMinutes: undefined,
      isBilled: false
    }, { shouldFocus: false });
  };

  const { fields: incidentalExpenseFields, append: appendIncidentalExpense, remove: removeIncidentalExpense } = useFieldArray({
    name: "incidentalExpenses",
    control
  });

  const appendNewIncidentalExpense = () => {
    appendIncidentalExpense({
      displayName: "",
      description: "",
      date: undefined,
      grossValue: undefined,
      vatValue: undefined,
      netValue: undefined,
      paidByUserOwnFunds: false,
      fileIds: [],
      isBilled: false
    }, { shouldFocus: false });
  };

  useEffect(() => {
    if(genericErrorsRef.current)
    {
      genericErrorsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [genericErrors]);

  const changeFeeEarnerLevel = (userId: string) => {
    setIsLoading(true);
    getFeeEarnerFromMatterFeeEarnerOrDefault(props.matterId, userId).then((response) => {
      setFeeEarnerLevel(response.data);
      setValue('feeEarnerLevelId', response.data?.id);
      populateActivities();
      removeRecordableItem();
      populateItemsOnChange();
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  };

  const changeChargingScheme = () => {
    populateActivities();
    populateItemsOnChange();
  };
  
  const populateActivities = () => {
    const chargingSchemeId: string = watch('chargingSchemeId');
    const feeEarnerLevelId: string | undefined = watch('feeEarnerLevelId');
    if(feeEarnerLevelId != undefined) {
      setIsLoadingActivities(true);
      getActivitiesForChargingSchemeFeeEarner(chargingSchemeId, feeEarnerLevelId!).then((response) => {
        setActivities(response.data);  
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoadingActivities(false);
      });
    }
  };

  const onBlurNumberOfMinutes = (index: number) => {
    const numberOfMinutes: number = watch(`recordableItems.${index}.numberOfMinutes`) ?? 0;
    const minutesPerUnit: number = watch(`recordableItems.${index}.minutesPerUnit`) ?? (watch(`recordableItems.${index}.chargingBasis`) == ChargingBasisIds.TimeId ? 1 : 0);

    if(minutesPerUnit) {
    setValue(`recordableItems.${index}.numberOfUnits`, Math.ceil(numberOfMinutes / minutesPerUnit));
    }

    trigger(`recordableItems.${index}.numberOfMinutes`);
  };

  const onBlurNumberOfUnits = (index: number) => {
    const numberOfUnits: number = watch(`recordableItems.${index}.numberOfUnits`) ?? 0;
    const minutesPerUnit: number = watch(`recordableItems.${index}.minutesPerUnit`) ?? (watch(`recordableItems.${index}.chargingBasis`) == ChargingBasisIds.TimeId ? 1 : 0);

    setValue(`recordableItems.${index}.numberOfMinutes`, Math.ceil(numberOfUnits * minutesPerUnit));
    
    trigger(`recordableItems.${index}.numberOfUnits`);
  }

  const onChangeActivity = (index: number) => {
    setValue(`recordableItems.${index}.startTime`, "");
    setValue(`recordableItems.${index}.endTime`, "");
    setValue(`recordableItems.${index}.numberOfMinutes`, undefined);
    setValue(`recordableItems.${index}.numberOfUnits`, undefined);
  };

  const onBlurTime = (index: number) => {
    const startTime: string = watch(`recordableItems.${index}.startTime`) ?? "";
    const endTime: string = watch(`recordableItems.${index}.endTime`) ?? "";    

    //clear startTime if it's not valid
    if (!startTime) {
      setValue(`recordableItems.${index}.startTime`, "");
      setValue(`recordableItems.${index}.numberOfMinutes`, undefined);
    }

    //clear endTime if it's not valid
    if (!endTime) {
      setValue(`recordableItems.${index}.endTime`, "");
      setValue(`recordableItems.${index}.numberOfMinutes`, undefined);
    }

    trigger(`recordableItems.${index}.startTime`);
    trigger(`recordableItems.${index}.endTime`);
    trigger(`recordableItems.${index}.numberOfMinutes`);

    //if startTime or endTime is invalid, don't go lower
    if (!startTime || !endTime) {
      return;
    }

    const calculatedMinutesStartTime: number = Number(startTime.split(":")[0]) * 60 + Number(startTime.split(":")[1]);
    const calculatedMinutesEndTime: number = Number(endTime.split(":")[0]) * 60 + Number(endTime.split(":")[1]);
    let calculatedMinutes: number;
    if(calculatedMinutesEndTime >= calculatedMinutesStartTime) {
      calculatedMinutes = calculatedMinutesEndTime - calculatedMinutesStartTime;
    }
    else {
      calculatedMinutes = calculatedMinutesEndTime + 24 * 60 - calculatedMinutesStartTime;
    }

    setValue(`recordableItems.${index}.numberOfMinutes`, calculatedMinutes);
    onBlurNumberOfMinutes(index);
  };

  const onSubmit = handleSubmit((data) => submitData(data));

  async function submitData(data: CreateOrUpdateMatterRecordableEventModel) {
    setIsLoading(true);
    removeEmptyFields(data);
    const requestBody = { ...data };
    requestBody.matterId = props.matterId;
    requestBody.recordableItems = data.recordableItems.map((x: CreateOrUpdateMatterRecordableItemModel) => (
      {
        ...x,
        //pad with seconds only if it's needed.
        ...(x.startTime && { startTime: x.startTime + ":00" }),
        ...(x.endTime && { endTime: x.endTime + ":00" })
      }));
    updateMatterRecordableEvent(requestBody, props.matterId, props.recordableEventId).then(() => {
      pageActions.triggerReloadPage();
      pageActions.triggerReloadBadge(BadgeIds.MatterRecordableItems);

      slidingPanelActions.clearSlidingPanel();
      reset();

      props.onSubmitCallback && props.onSubmitCallback();
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  };

  const cancelForm = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    slidingPanelActions.clearSlidingPanel();
    reset();

    currentSlidingPanelState.onCancel !== undefined && currentSlidingPanelState?.onCancel();
  };

  const populateItemsOnChange = () => {
    const chargingSchemeId: string = watch('chargingSchemeId');
    const eventTypeId: string | undefined = watch('eventTypeId');
    const feeEarnerLevelId: string | undefined = watch('feeEarnerLevelId');

    if (chargingSchemeId == undefined || feeEarnerLevelId == undefined) {
      return;
    }

    setIsLoading(true);
    removeRecordableItem();
    if(eventTypeId != undefined) {
      getActivitiesForChargingSchemeFeeEarnerAndEventType(chargingSchemeId, feeEarnerLevelId!, eventTypeId).then((response) => {
        if (response.data.length == 0) {
          appendNewRecordableItem();
          return;
        }
        response.data.forEach((x: ActivityDropdownForRecordTimeModel, index: number) => {
          appendRecordableItem({
            activityId: x.id,
            startTime: "",
            endTime: "",
            numberOfMinutes: undefined,
            chargingBasis: x.chargingBasis,
            minutesPerUnit: Number(x.minutesPerUnit)
          }, { shouldFocus: false });
        })
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
    }
    else {
      appendNewRecordableItem();
      setIsLoading(false);
    }
  };
  
  const computeIncidentalExpenseNetValue = (index: number) => {
    const grossValue: number = watch(`incidentalExpenses.${index}.grossValue`) ?? 0;
    const vatValue: number = watch(`incidentalExpenses.${index}.vatValue`) ?? 0;

    setValue(`incidentalExpenses.${index}.netValue`, grossValue - vatValue);
  };

  const onChangeHasIncidentalExpenses = (value: boolean) => {
    if(value) {
      appendNewIncidentalExpense();
    }
    else {
      removeIncidentalExpense();
    }
  };

  return (
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div ref={genericErrorsRef} className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Form onSubmit={onSubmit} className="d-flex flex-column h-100">
        <Row>
          {hasBilledItems ? (
            <Form.Group as={Col} sm={6}>
              <Field
                label={"Fee Earner"}
                value={initialRecordableEvent?.user?.name}
              />
            </Form.Group>
          ) : (
            <Form.Group as={Col} sm={6} controlId="userId">
              <Form.Label className="required">Fee Earner</Form.Label>
              <Controller
                control={control}
                name={`userId`}
                shouldUnregister={true}
                render={({ field: { onChange, value, name, ref } }) => (
                  <CustomSelect
                    id="userId"
                    inputRef={ref}
                    className={`lp-select${errors?.userId?.message ? ' invalid' : ''}`}
                    endpointCall={() => getUserSummaryForLte(user.lawPageTradingEntityId!)}
                    value={value}
                    onChange={val => (onChange(val?.id ?? null), changeFeeEarnerLevel(val?.id))}
                  />
                )}
              />
              <Form.Text className="lp-error">
                {errors?.userId?.message && (errors.userId?.message)}
              </Form.Text>
            </Form.Group>
          )}

          <Form.Group as={Col} sm={6} className="mt-4 mt-sm-0">
            <Field
              label={"Fee Earner Level"}
              value={feeEarnerLevel?.name}
            />
          </Form.Group>
        </Row>

        <Row>
          {hasBilledItems ? (
            <Form.Group as={Col}>
              <Field
                label={"Client"}
                value={initialRecordableEvent?.matterParticipatingEntity?.name}
              />
            </Form.Group>
          ) : (
            <Form.Group as={Col} controlId="matterParticipatingEntityId">
              <Form.Label className={clients.length > 0 ? 'required' : ''}>Client</Form.Label>
              <Controller
                control={control}
                name={`matterParticipatingEntityId`}
                shouldUnregister={true}
                render={({ field: { onChange, value, name, ref } }) => (
                  <CustomSelect
                    id="matterParticipatingEntityId"
                    inputRef={ref}
                    className={`lp-select${errors?.matterParticipatingEntityId?.message ? ' invalid' : ''}`}
                    options={clients}
                    isLoading={isLoadingClients}
                    value={value}
                    onChange={val => onChange(val?.id ?? null)}
                  />
                )}
              />
              <Form.Text className="lp-error">
                {errors?.matterParticipatingEntityId?.message && (errors.matterParticipatingEntityId?.message)}
              </Form.Text>
            </Form.Group>
          )}
        </Row>

        <Row>
          {hasBilledItems ? (
            <Form.Group as={Col} sm={6}>
              <Field
                label={"Date"}
                value={initialRecordableEvent?.date ? moment(initialRecordableEvent?.date).format(DateFormat.Moment) : "—"}
              />
            </Form.Group>
          ) : (
            <Form.Group as={Col} sm={6} controlId="date">
              <Form.Label className="required">Date</Form.Label>
              <Controller
                control={control}
                name="date"
                shouldUnregister={true}
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    className={`${errors?.date?.message ? 'invalid' : ''}`}
                    id="date"
                    dateFormat={DateFormat.Datepicker}
                    selected={value ? getDateOnly(value) : null}
                    onChange={val => onChange(val != null ? getDateOnly(val) : val)}
                    showMonthDropdown
                    showYearDropdown
                    autoComplete="off"
                  />
                )}
              />
              <Form.Text className="lp-error">
                {errors?.date?.message && (errors.date.message)}
              </Form.Text>
            </Form.Group>
          )}
          
          {hasBilledItems ? (
            <Form.Group as={Col} sm={6} className="mt-4 mt-sm-0">
              <Field
                label={"Display Name"}
                value={initialRecordableEvent?.displayName}
              />
            </Form.Group>
          ) : (
            <Form.Group as={Col} sm={6} className="mt-4 mt-sm-0" controlId="displayName">
              <Form.Label className="required">Display Name</Form.Label>
              <Form.Control
                type="text"
                className={`${errors?.displayName?.message ? 'invalid' : ''}`}
                {...register(`displayName`, {shouldUnregister: true})}
              />
              <Form.Text className="lp-error">
                {errors?.displayName?.message && (errors.displayName?.message)}
              </Form.Text>
            </Form.Group>
          )}
        </Row>

        <Row>
          {hasBilledItems ? (
              <Form.Group as={Col}>
                <Field
                  label={"Description"}
                  value={initialRecordableEvent?.description}
                />
              </Form.Group>
            ) : (
              <Form.Group as={Col} controlId="description">
                <Form.Label className="required">Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={5}
                  className={`${errors?.description?.message ? 'invalid' : ''}`}
                  {...register(`description`, {shouldUnregister: true})}
                />
                <Form.Text className="lp-error">
                  {errors?.description?.message && (errors.description?.message)}
                </Form.Text>
              </Form.Group>
            )
          }
        </Row>

        <Row>
          {hasBilledItems ? (
            <Form.Group as={Col} sm={hasBilledItems ? 6 : 12}>
              <Field
                label={"Charging Scheme"}
                value={initialRecordableEvent?.chargingScheme?.name}
              />
            </Form.Group>
          ) : (
            <Form.Group as={Col} sm={hasBilledItems ? 6 : 12} controlId="chargingSchemeId">
              <Form.Label className="required">Charging Scheme</Form.Label>
              <Controller
                control={control}
                name={`chargingSchemeId`}
                shouldUnregister={true}
                render={({ field: { onChange, value, name, ref } }) => (
                  <CustomSelect
                    id="chargingSchemeId"
                    inputRef={ref}
                    className={`lp-select${errors?.chargingSchemeId?.message ? ' invalid' : ''}`}
                    endpointCall={() => getChargingSchemesFromLte(user.lawPageTradingEntityId!)}
                    value={value}
                    onChange={val => (onChange(val?.id ?? null), changeChargingScheme())}
                  />
                )}
              />
              <Form.Text className="lp-error">
                {errors?.chargingSchemeId?.message && (errors.chargingSchemeId?.message)}
              </Form.Text>
            </Form.Group>
          )}

          {/*hasBilledItems ? (
              <Form.Group as={Col} sm={6} className="mt-4 mt-sm-0">
                <Field
                  label={"Event Type"}
                  value={initialRecordableEvent?.recordableEventType?.name}
                />
              </Form.Group>
            ) : (
              <Form.Group as={Col} sm={6} className="mt-4 mt-sm-0" controlId="eventTypeId">
                <Form.Label>Event Type</Form.Label>
                <Controller
                  control={control}
                  name={`eventTypeId`}
                  shouldUnregister={true}
                  render={({ field: { onChange, value, name, ref } }) => (
                    <CustomSelect
                      id="eventTypeId"
                      inputRef={ref}
                      className={`lp-select${errors?.eventTypeId?.message ? ' invalid' : ''}`}
                      endpointCall={getAllRecordableEventTypes}
                      value={value}
                      onChange={val => (onChange(val?.id ?? null), populateItemsOnChange())}
                      isClearable
                    />
                  )}
                />
                <Form.Text className="lp-error">
                  {errors?.eventTypeId?.message && (errors.eventTypeId?.message)}
                </Form.Text>
              </Form.Group>
            )
          */}
        </Row>

        {recordableItemFields.map((field, index) => {
          return (
            <div key={field.id} className="lp-charge-rate-item">
              {!field.isBilled ? (
                  <Button
                    variant="danger"
                    onClick={() => removeRecordableItem(index)}
                    className={`delete-item btn-icon${recordableItemFields.length === 1 ? ' disabled' : ''}`}
                  >
                    <MdClose />
                  </Button>
                ) : (
                  <span className="billed-icon">Billed</span>
                )
              }

              <Form.Group className="mb-4" controlId={`recordableItems.${index}.activityId`}>
                <Form.Label className="required">Activity</Form.Label>
                <Controller
                  control={control}
                  name={`recordableItems.${index}.activityId`}
                  shouldUnregister={true}
                  render={({ field: { onChange, value, name, ref } }) => (
                    <CustomSelect
                      id="activityId"
                      inputRef={ref}
                      className={`lp-select${errors?.recordableItems?.[index]?.activityId?.message ? ' invalid' : ''}`}
                      options={activities}
                      isLoading={isLoadingActivities}
                      value={value}
                      isDisabled={field.isBilled}
                      onChange={val => (onChange(val?.id ?? null), onChangeActivity(index), setValue(`recordableItems.${index}.chargingBasis`, val.chargingBasis), setValue(`recordableItems.${index}.minutesPerUnit`, val.minutesPerUnit))}
                      placeholder={ watch(`recordableItems.${index}.isActivityInvalid`) ? "Activity is outdated. Please select a new one." : undefined}
                    />
                  )}
                />
                <Form.Text className="lp-error">
                  {errors.recordableItems?.[index]?.activityId?.message && (errors.recordableItems?.[index]?.activityId?.message)}
                </Form.Text>
              </Form.Group>
              {watch(`recordableItems.${index}.chargingBasis`) == ChargingBasisIds.TimeId &&
                <Row>
                  <>
                    <Form.Group as={Col} xs={12} sm={6} className="mb-4 mb-sm-0" controlId={`recordableItems.${index}.startTime`}>
                      <Form.Label
                        className={!!watch(`recordableItems.${index}.endTime`) ? "required" : ""}
                      >
                        Start Time
                      </Form.Label>
                      <Form.Control
                        type="time"
                        disabled={field.isBilled || (!!watch(`recordableItems.${index}.numberOfMinutes`) && !watch(`recordableItems.${index}.startTime`) && !watch(`recordableItems.${index}.endTime`))}
                        className={`${errors?.recordableItems?.[index]?.startTime?.message ? 'invalid' : ''}`}
                        {...register(`recordableItems.${index}.startTime`, {shouldUnregister: true})}
                        onBlur={() => onBlurTime(index)}
                      />
                      <Form.Text className="lp-error">
                        {errors?.recordableItems?.[index]?.startTime?.message && (errors.recordableItems?.[index]?.startTime?.message)}
                      </Form.Text>
                    </Form.Group>

                    <Form.Group as={Col} xs={12} sm={6} controlId={`recordableItems.${index}.endTime`}>
                      <Form.Label
                        className={!!watch(`recordableItems.${index}.startTime`) ? "required" : ""}
                      >
                        End Time
                      </Form.Label>
                      <Form.Control
                        type="time"
                        disabled={field.isBilled || (!!watch(`recordableItems.${index}.numberOfMinutes`) && !watch(`recordableItems.${index}.startTime`) && !watch(`recordableItems.${index}.endTime`))}
                        className={`${errors?.recordableItems?.[index]?.endTime?.message ? 'invalid' : ''}`}
                        {...register(`recordableItems.${index}.endTime`, {shouldUnregister: true})}
                        onBlur={() => onBlurTime(index)}
                      />
                      <Form.Text className="lp-error">
                        {errors?.recordableItems?.[index]?.endTime?.message && (errors.recordableItems?.[index]?.endTime?.message)}
                      </Form.Text>
                    </Form.Group>
                  </>
                </Row>
              }
              <Row>
                <Form.Group as={Col} xs={12} sm={6} className="mb-4 mb-sm-0" controlId={`recordableItems.${index}.numberOfMinutes`}>
                  <Form.Label className={watch(`recordableItems.${index}.chargingBasis`) == ChargingBasisIds.TimeId ? "required" : ""}>
                    Number of Minutes
                    {(watch(`recordableItems.${index}.numberOfMinutes`) ?? 0) > RecordableEventMinutesWarningLimit && 
                      <TooltipIcon type="warning" text={`Exceeds ${RecordableEventMinutesWarningLimit} minutes`} />
                    }
                  </Form.Label>
                  <Form.Control
                    type="number"
                    disabled={field.isBilled || (!!watch(`recordableItems.${index}.startTime`) || !!watch(`recordableItems.${index}.endTime`)) || watch(`recordableItems.${index}.chargingBasis`) != ChargingBasisIds.TimeId}
                    className={`${errors?.recordableItems?.[index]?.numberOfMinutes?.message ? 'invalid' : ''}`}
                    {...register(`recordableItems.${index}.numberOfMinutes`, {shouldUnregister: true})}
                    onBlur={() => onBlurNumberOfMinutes(index)}
                    min="0"
                    onWheel={e => e.currentTarget.blur()}
                  />
                  <Form.Text className="lp-error">
                    {errors?.recordableItems?.[index]?.numberOfMinutes?.message && (errors.recordableItems?.[index]?.numberOfMinutes?.message)}
                  </Form.Text>
                </Form.Group>

                <Form.Group as={Col} xs={12} sm={6} controlId={`recordableItems.${index}.numberOfUnits`}>
                  <Form.Label className={watch(`recordableItems.${index}.chargingBasis`) != ChargingBasisIds.TimeId ? "required" : ""}>
                    Number of Units
                  </Form.Label>
                  <Form.Control
                    type="number"
                    disabled={field.isBilled || watch(`recordableItems.${index}.chargingBasis`) == ChargingBasisIds.TimeId}
                    {...register(`recordableItems.${index}.numberOfUnits`, {shouldUnregister: true})}
                    onBlur={() => onBlurNumberOfUnits(index)}
                    onWheel={e => e.currentTarget.blur()}
                  />
                  <Form.Text className="lp-error">
                    {errors?.recordableItems?.[index]?.numberOfUnits?.message && (errors.recordableItems?.[index]?.numberOfUnits?.message)}
                  </Form.Text>
                </Form.Group>
              </Row>
            </div>
          );
        })}

        {recordableItemFields.length > 0 &&
          <Form.Group className="mb-4">
            <Button variant="light" onClick={() => appendNewRecordableItem()}>
              <MdAdd />
              Add new Activity
            </Button>
          </Form.Group>
        }

        <Form.Group className="lp-inline-radio-group mb-4" controlId="hasIncidentalExpenses">
          <Controller
            control={control}
            name="hasIncidentalExpenses"
            shouldUnregister={true}
            render={({field: { onChange, value, name, ref }}) => (
              <Form.Check 
                type="checkbox"
                id="hasIncidentalExpenses">
                  <Form.Check.Input
                    className="form-check-input"
                    ref={ref}
                    checked={value ?? false}
                    onChange={(ev: any) => { onChange(ev.target.checked); onChangeHasIncidentalExpenses(ev.target.checked); }}
                  />
              </Form.Check>
            )}
          />
          <Form.Label>Has Incidental Expenses</Form.Label>
        </Form.Group>

        {watch("hasIncidentalExpenses") &&
          <>
            <div className="lp-color-title primary full-width">Incidental Expenses</div>
            {incidentalExpenseFields.map((field, index) => {
              return (
                <div key={field.id} className="lp-charge-rate-item">
                  {!field.isBilled ? (
                      <Button
                        variant="danger"
                        onClick={() => removeIncidentalExpense(index)}
                        className={`delete-item btn-icon${incidentalExpenseFields.length === 1 ? ' disabled' : ''}`}
                      >
                        <MdClose />
                      </Button>
                    ) : (
                      <span className="billed-icon">Billed</span>
                    )
                  }

                  <Row>
                    <Form.Group as={Col} xs={12} sm={6} className="mb-4" controlId={`incidentalExpenses.${index}.date`}>
                      <Form.Label className="required">Date</Form.Label>
                      <Controller
                        control={control}
                        name={`incidentalExpenses.${index}.date`}
                        shouldUnregister={true}
                        render={({ field: { onChange, value } }) => (
                          <DatePicker
                            className={`${errors?.incidentalExpenses?.[index]?.date?.message ? 'invalid' : ''}`}
                            id={`incidentalExpenses.${index}.date`}
                            dateFormat={DateFormat.Datepicker}
                            selected={value ? getDateOnly(value) : null}
                            onChange={(val) => onChange(val != null ? getDateOnly(val) : val)}
                            showMonthDropdown
                            showYearDropdown
                            disabled={field.isBilled}
                            autoComplete="off"
                          />
                        )}
                      />
                      <Form.Text className="lp-error">
                        {errors?.incidentalExpenses?.[index]?.date?.message && (errors.incidentalExpenses?.[index]?.date?.message)}
                      </Form.Text>
                    </Form.Group>

                    <Form.Group as={Col} xs={12} sm={6} className="mb-4" controlId={`incidentalExpenses.${index}.displayName`}>
                      <Form.Label className="required">Display Name</Form.Label>
                      <Form.Control
                        type="text"
                        className={`${errors?.incidentalExpenses?.[index]?.displayName?.message ? 'invalid' : ''}`}
                        {...register(`incidentalExpenses.${index}.displayName`, {shouldUnregister: true})}
                        disabled={field.isBilled}
                      />
                      <Form.Text className="lp-error">
                        {errors?.incidentalExpenses?.[index]?.displayName?.message && (errors.incidentalExpenses?.[index]?.displayName?.message)}
                      </Form.Text>
                    </Form.Group>
                  </Row>

                  <Form.Group className="mb-4" controlId={`incidentalExpenses.${index}.description`}>
                    <Form.Label className="required">Description</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={5}
                      className={`${errors?.incidentalExpenses?.[index]?.description?.message ? 'invalid' : ''}`}
                      {...register(`incidentalExpenses.${index}.description`, {shouldUnregister: true})}
                      disabled={field.isBilled}
                    />
                    <Form.Text className="lp-error">
                      {errors?.incidentalExpenses?.[index]?.description?.message && (errors.incidentalExpenses?.[index]?.description?.message)}
                    </Form.Text>
                  </Form.Group>

                  <Form.Group className="mb-4" controlId={`incidentalExpenses.${index}.paidByUserOwnFunds`}>
                    <Form.Label>Paid By User's Own Funds</Form.Label>
                    <Controller
                      control={control}
                      name={`incidentalExpenses.${index}.paidByUserOwnFunds`}
                      shouldUnregister={true}
                      render={({field: { onChange, value, name, ref }}) => (
                        <Form.Check 
                          type="switch"
                          id={`incidentalExpenses.${index}.paidByUserOwnFunds`}>
                            <Form.Check.Input
                              className= "form-check-input"
                              ref={ref}
                              checked={value ?? false}
                              onChange={(ev: any) => onChange(ev.target.checked)}
                              disabled={field.isBilled}
                            />
                        </Form.Check>
                      )}
                    />
                  </Form.Group>

                  <Row>
                    <Form.Group as={Col} xs={12} sm={4} className="mb-4" controlId={`incidentalExpenses.${index}.grossValue`}>
                      <Form.Label className={"required"}>Gross Value</Form.Label>
                      <Form.Control
                        type="number"
                        className={`${errors?.incidentalExpenses?.[index]?.grossValue?.message ? 'invalid' : ''}`}
                        {...register(`incidentalExpenses.${index}.grossValue`, {shouldUnregister: true})}
                        min="0.00"
                        step="0.01"
                        onBlur={() => computeIncidentalExpenseNetValue(index)}
                        disabled={field.isBilled}
                        onWheel={e => e.currentTarget.blur()}
                      />
                      <Form.Text className="lp-error">
                        {errors?.incidentalExpenses?.[index]?.grossValue?.message && (errors.incidentalExpenses?.[index]?.grossValue?.message)}
                      </Form.Text>
                    </Form.Group>

                    <Form.Group as={Col} xs={12} sm={4} className="mb-4" controlId={`incidentalExpenses.${index}.vatValue`}>
                      <Form.Label>VAT Value</Form.Label>
                      <Form.Control
                        type="number"
                        className={`${errors?.incidentalExpenses?.[index]?.vatValue?.message ? 'invalid' : ''}`}
                        {...register(`incidentalExpenses.${index}.vatValue`, {shouldUnregister: true})}
                        min="0.00"
                        step="0.01"
                        onBlur={() => computeIncidentalExpenseNetValue(index)}
                        disabled={field.isBilled}
                        onWheel={e => e.currentTarget.blur()}
                      />
                      <Form.Text className="lp-error">
                        {errors?.incidentalExpenses?.[index]?.vatValue?.message && (errors.incidentalExpenses?.[index]?.vatValue?.message)}
                      </Form.Text>
                    </Form.Group>

                    <Form.Group as={Col} xs={12} sm={4} className="mb-4" controlId={`incidentalExpenses.${index}.netValue`}>
                      <Form.Label>Net Value</Form.Label>
                      <Form.Control
                        type="number"
                        disabled
                        className={`${errors?.incidentalExpenses?.[index]?.netValue?.message ? 'invalid' : ''}`}
                        {...register(`incidentalExpenses.${index}.netValue`, {shouldUnregister: true})}
                      />
                      <Form.Text className="lp-error">
                        {errors?.incidentalExpenses?.[index]?.netValue?.message && (errors.incidentalExpenses?.[index]?.netValue?.message)}
                      </Form.Text>
                    </Form.Group>
                  </Row>

                  <Form.Group controlId={`incidentalExpenses.${index}.fileIds`}>
                    <Form.Label>Proofs of Purchase</Form.Label>
                    <Controller
                      control={control}
                      name={`incidentalExpenses.${index}.fileIds`}
                      shouldUnregister={true}
                      render={({ field: { onChange, value, name, ref } }) => (
                        <MatterFileSelect
                          id={`incidentalExpenses.${index}.fileIds`}
                          matterId={props.matterId}
                          inputRef={ref}
                          className={`lp-select${errors?.incidentalExpenses?.[index]?.fileIds?.message ? ' invalid' : ''}`}
                          value={value}
                          onChange={val => onChange(val ?? null)}
                          isMulti
                          isClearable
                          menuPlacement="top"
                          canUploadNewFiles
                          isDisabled={field.isBilled}
                        />
                      )}
                    />
                    <Form.Text className="lp-error">
                      {errors?.incidentalExpenses?.[index]?.fileIds?.message && (errors.incidentalExpenses?.[index]?.fileIds?.message)}
                    </Form.Text>
                  </Form.Group>
                </div>
              );
            })}

            <Form.Group className="mb-4">
              <Button variant="light" onClick={() => appendNewIncidentalExpense()}>
                <MdAdd />
                Add new Incidental Expense
              </Button>
            </Form.Group>
          </>
        }

        <div className="lp-slide-panel-sticky-bottom">
          <Form.Group className="d-flex justify-content-between">
            { Object.keys(errors).length
              ? <FormErrorButton text="Update" />
              : <Button variant="success" type="submit">Update</Button>
            }
            <Button variant="secondary-400" onClick={cancelForm}>Cancel</Button>
          </Form.Group>
        </div>
      </Form>
    </>
  );
}